/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  DataGridPro,
  GridColDef,
  GridPaginationModel,
} from "@mui/x-data-grid-pro";
import {
  Stack,
  Tooltip,
  FormControl,
  InputAdornment,
  TextField,
  IconButton,
  MenuItem,
  Select,
  AvatarGroup,
  Avatar,
  InputLabel,
} from "@mui/material";
import NoRows from "../../components/DataGrid/animations/NoRows";
import Header from "../../components/Header";
import SearchIcon from "@mui/icons-material/Search";
import Copyright from "../../components/Copyright";
import { useData } from "../../providers/DataProvider";
import { BonusProps, BuilderInfo, RegionProps } from "../../providers/types";
import { ChangeEvent, useEffect } from "react";
import RoleModal from "../../modals/RoleModal";
import ReviewerViewModal from "../../modals/ReviewerViewModal";
import VisibilityIcon from "@mui/icons-material/VisibilityRounded";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUploadRounded";
import TextSnippetRoundedIcon from "@mui/icons-material/TextSnippetRounded";
import PayrollModal from "../../modals/PayrollModal";
import AxiosInstance from "../../utils/axiosInstance";
import BuilderSplitModal from "../../components/BuilderSplitModalReview";
import moment from "moment";
const theme = createTheme();

type FilterItem = {
  id?: string | number;
  field: string;
  operator: string;
  value: string | string[]; // Allow value to be string or array of strings
};

type Filters = {
  items: FilterItem[];
  quickFilterValues: string[];
};

const defaultFilter: Filters = {
  items: [],
  quickFilterValues: [""],
};

export default function Reviewer() {
  const { regions, bonuses, loadBonuses, role, loadingBonuses } = useData();
  const [open, setOpen] = React.useState<boolean>(false);
  const [filt, setFilt] = React.useState<Filters>(defaultFilter);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 50,
  });
  const [selectedStatusType, setSelectedStatusType] = React.useState<
    number | string | null
  >("All");
  // Define a default 'All' region object
  const allRegions: RegionProps = {
    regionId: "All",
    regionName: "All Regions",
  };

  // Initialize selectedRegion with the 'All' object
  const [selectedRegion, setSelectedRegion] =
    React.useState<RegionProps>(allRegions);

  function stringAvatar(name: string) {
    return {
      sx: {
        width: 24,
        height: 24,
      },
      children: `${name.split(" ")[0][0] || ""}${name.split(" ")[1][0] || ""}`,
    };
  }
  const downloadFile = async (url: string, filename: string) => {
    try {
      const response = await AxiosInstance.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("File download error:", error);
    }
  };

  const downloadSelectionSheet = async (jobId: string, bonus: any) => {
    const address = bonus.jobAddress.replace(/\s+/g, "");
    await downloadFile(
      `/api/cba/SelectionSheetsPdf/${jobId}`,
      `${bonus?.projectNumber || ""}${bonus?.projectNumber ? "-" : ""}${
        bonus?.jobNumber || ""
      }${bonus?.jobNumber ? "-" : ""}${address}-SelectionSheet.pdf`
    );
  };

  const downloadBuilderFolder = async (jobId: string, bonus: any) => {
    const address = bonus.jobAddress.replace(/\s+/g, "");
    await downloadFile(
      `/api/cba/mergedpdf/${jobId}`,
      `${bonus?.projectNumber || ""}${bonus?.projectNumber ? "-" : ""}${
        bonus?.jobNumber || ""
      }${bonus?.jobNumber ? "-" : ""}${address}-ConstructionFolder.pdf`
    );
  };

  const formatStatus = (status: number, bonus: BonusProps): string => {
    const isDefaultDate = (date: string | Date) =>
      moment(date).isSame(moment("0001-01-01"));

    switch (status) {
      case 1:
        return "New";
      case 2:
        return "PM Review";
      case 3:
        return "SPM Review";
      case 4:
        return "DM Review";
      case 5:
        return "Ops Review";
      case 6:
        return "Approved";
      case 7:
        return "Rejected";
      case 8:
        return "Busted";
      case 9:
        return bonus?.payrollProcessedOn &&
          !isDefaultDate(bonus.payrollProcessedOn)
          ? `Completed on ${moment(bonus.payrollProcessedOn).format("LL")}`
          : "Completed";
      case 10:
        return bonus?.payrollPaidOn && !isDefaultDate(bonus.payrollPaidOn)
          ? `Paid on ${moment(bonus.payrollPaidOn).format("LL")}`
          : "Paid";
      default:
        return "";
    }
  };

  const columns: GridColDef[] = [
    {
      field: "jobAddress",
      headerName: "Address",
      minWidth: 220,
      flex: 1,
      filterable: true,
      renderCell: (params) => (
        <Tooltip title={params.row.jobAddress}>{params.value}</Tooltip>
      ),
    },
    {
      field: "subdivision",
      headerName: "Community",
      minWidth: 220,
      flex: 1,
      filterable: true,
    },
    {
      field: "projectNumber",
      headerName: "Project",
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      filterable: true,
      renderCell: (params) => {
        return params.row?.payoutInfo?.projectNumber;
      },
    },
    {
      field: "jobNumber",
      headerName: "Job",
      headerAlign: "center",
      align: "center",
      minWidth: 120,
      filterable: true,
    },
    {
      field: "regionName",
      headerName: "Region",
      headerAlign: "center",
      align: "center",
      minWidth: 180,
      flex: 1,
      filterable: true,
    },
    {
      field: "statusTypeId",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      minWidth: 220,
      flex: 1,
      filterable: true,
      renderCell: (params) => {
        return formatStatus(params.row.statusTypeId, params.row);
      },
    },
    {
      field: "builders",
      headerName: "Builders",
      filterable: true,
      minWidth: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <AvatarGroup
            max={4}
            sx={{
              mt: 1,
              "& .MuiAvatar-root": { width: 28, height: 28, fontSize: 15 },
              justifyContent: "center",
            }}
          >
            {params.row?.builders?.map((row: BuilderInfo) => {
              if (row.builderName.split(" ").length >= 2) {
                return (
                  <Tooltip title={row.builderName}>
                    <Avatar
                      alt={row?.builderName}
                      {...stringAvatar(row.builderName)}
                    />
                  </Tooltip>
                );
              } else {
                return (
                  <Avatar
                    alt={row?.builderName}
                    sx={{ height: 24, width: 24 }}
                  />
                );
              }
            })}
          </AvatarGroup>
        );
      },
    },
    {
      field: "id",
      headerName: "Actions",
      align: "right",
      headerAlign: "right",
      minWidth: 220,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0.5} justifyContent="flex-end">
            {role?.trim() === "Uploader" && (
              <Tooltip title={"View Files"}>
                <IconButton href={`/upload?job=${params.row.jobId}`}>
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>
            )}
            {(params.row?.statusTypeId === 6 ||
              params.row?.statusTypeId === 9 ||
              params.row?.statusTypeId === 10) && (
              <Box>
                <Tooltip title={"Download Construction Folder"}>
                  <IconButton
                    onClick={() =>
                      downloadBuilderFolder(params.row.jobId, params.row)
                    }
                  >
                    <DriveFolderUploadIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {(params.row?.statusTypeId === 6 ||
              params.row?.statusTypeId === 9 ||
              params.row?.statusTypeId === 10) && (
              <Box>
                <Tooltip title={"Download Selection Sheet"}>
                  <IconButton
                    onClick={() =>
                      downloadSelectionSheet(params.row.jobId, params.row)
                    }
                  >
                    <TextSnippetRoundedIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            <Box>
              <BuilderSplitModal jobId={params.row.jobId} />
            </Box>
            {role?.trim() === "Payroll" && (
              <PayrollModal jobId={params.row.jobId as number} />
            )}
            {role?.trim() !== "Payroll" && role?.trim() !== "Uploader" && (
              <Box>
                {params.row.statusTypeId === 6 ? (
                  <PayrollModal jobId={params.row.jobId as number} />
                ) : (
                  <ReviewerViewModal jobId={params.row.jobId as number} />
                )}
              </Box>
            )}
          </Stack>
        );
      },
    },
  ];

  const handleStatusTypeChange = (event: ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as number | null;
    setSelectedStatusType(selectedValue);
    // Update filter based on selected status type
    updateFilter2("statusTypeId", selectedValue);
  };

  const updateFilter = (field: string, value: any) => {
    setFilt((prevFilt) => {
      const filteredItems = prevFilt.items.filter(
        (item) => item.field !== field
      );
      const newItems = value
        ? [
            {
              id: `${field}-${value}`,
              field: field,
              operator: "equals",
              value: value,
            },
          ]
        : [];

      return {
        ...prevFilt,
        items: [...filteredItems, ...newItems],
      };
    });
  };

  const updateFilter2 = (field: string, value: number | null) => {
    setFilt((prevFilt) => {
      const filteredItems = prevFilt.items.filter(
        (item) => item.field !== field && item.field !== "regionId"
      );

      let newItems: FilterItem[] = [];

      // Update region filter
      const regionFilter =
        selectedRegion.regionId !== "All"
          ? {
              id: `regionId-${selectedRegion.regionId}`,
              field: "regionId",
              operator: "equals",
              value: selectedRegion.regionId,
            }
          : null;
      // if (regionFilter) {
      //   //@ts-ignore
      //   newItems.push(regionFilter);
      // }

      // Update status filter
      const statusFilter =
        //@ts-ignore
        value !== null && value !== "All"
          ? {
              id: `${field}-${value}`,
              field: field,
              operator: "equals",
              value: value.toString(),
            }
          : null;
      if (statusFilter) {
        newItems.push(statusFilter);
      }

      return {
        ...prevFilt,
        items: [...filteredItems, ...newItems],
      };
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFilt((prevFilt) => ({
      ...prevFilt,
      quickFilterValues: [newValue],
    }));
  };

  useEffect(() => {
    if (role) {
      loadBonuses(role);
    } else {
      setOpen(true);
    }
  }, [role]);

  return (
    <ThemeProvider theme={theme}>
      <Header title="Reviewer Portal" />
      <Container component="main" maxWidth={false}>
        <CssBaseline />
        <Box
          width={"100%"}
          sx={{
            mt: (theme) => theme.spacing(10),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "75vh",
              width: "100%",
              mt: (theme) => theme.spacing(2),
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "column", md: "row" },
                justifyContent: "space-between",
                py: (theme) => theme.spacing(1),
                my: (theme) => theme.spacing(1.5),
              }}
            >
              <Stack
                sx={{
                  width: {
                    md: "60%",
                    lg: "50%",
                  },
                }}
                direction="row"
                alignItems="flex-start"
                spacing={1}
              >
                <FormControl fullWidth sx={{ maxWidth: 300 }}>
                  <TextField
                    sx={{ maxWidth: "lg", width: "100%" }}
                    size="small"
                    label="Search"
                    variant="outlined"
                    onChange={handleSearchChange}
                    placeholder="Search..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon color="info" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ maxWidth: 300 }}>
                  <InputLabel>Region</InputLabel>
                  <Select
                    size="small"
                    label="Region"
                    value={selectedRegion.regionId}
                    onChange={(event) => {
                      const regionId = event.target.value; // This is always a string from the select input.
                      const region =
                        regionId === "All"
                          ? allRegions
                          : regions.find(
                              (r) =>
                                r.regionId === parseInt(regionId as any, 10)
                            ); // Safely parse as integer

                      setSelectedRegion(region as RegionProps);
                      updateFilter(
                        "regionName",
                        region && region.regionId !== "All"
                          ? region.regionName
                          : null
                      );
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Select Region" }}
                  >
                    <MenuItem value="All">
                      {" "}
                      <em>All</em>
                    </MenuItem>
                    {regions.map((region) => (
                      <MenuItem
                        key={region.regionId}
                        value={String(region.regionId)}
                      >
                        {region.regionName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ maxWidth: 300 }}>
                  <InputLabel>Status</InputLabel>
                  <Select
                    size="small"
                    label="Status"
                    InputLabelProps={{ shrink: true }}
                    value={selectedStatusType || ""}
                    // @ts-ignore
                    onChange={handleStatusTypeChange}
                    inputProps={{ "aria-label": "Select Status Type" }}
                  >
                    <MenuItem value="All">
                      <em>All</em>
                    </MenuItem>
                    {/* Map status types to options */}
                    {bonuses
                      .reduce((unique, bonus) => {
                        if (
                          !unique.some(
                            // @ts-ignore
                            (obj) => obj.statusTypeId === bonus.statusTypeId
                          )
                        ) {
                          // @ts-ignore
                          unique.push(bonus);
                        }
                        return unique;
                      }, [])
                      .map((uniqueBonus) => (
                        <MenuItem
                          // @ts-ignore
                          key={uniqueBonus.statusTypeId}
                          // @ts-ignore
                          value={uniqueBonus.statusTypeId}
                        >
                          {
                            //@ts-ignore
                            formatStatus(uniqueBonus.statusTypeId)
                          }
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Stack>
              {/* <Stack
                direction="row"
                alignItems="flex-end"
                justifyContent="flex-end"
                spacing={2}
                sx={{
                  flex: 1,
                  direction: "row",
                  mt: { sm: 2, md: 0 },
                }}
              >
                <DateRangePicker />
              </Stack> */}
            </Box>

            <DataGridPro
              sx={{
                mt: (theme) => theme.spacing(2),
                ".MuiTablePagination-displayedRows": {
                  display: "none !important",
                },
              }}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
              }}
              getRowId={(row) => row.bonusId}
              paginationModel={paginationModel}
              onPaginationModelChange={(model: GridPaginationModel) =>
                setPaginationModel({
                  page: model.page,
                  pageSize: model.pageSize,
                })
              }
              filterModel={filt}
              onFilterModelChange={(newFilterModel) =>
                setFilt(newFilterModel as Filters)
              }
              disableRowSelectionOnClick
              columns={columns}
              rows={bonuses}
              rowCount={bonuses.length}
              slots={{
                noRowsOverlay: NoRows,
              }}
              slotProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: false,

                  density: "comfortable",
                  sx: {
                    padding: (theme) => theme.spacing(2),
                  },
                },
              }}
              loading={loadingBonuses}
            />
          </Box>
        </Box>
        <Copyright />
        <RoleModal open={open} setOpen={setOpen} />
      </Container>
    </ThemeProvider>
  );
}
