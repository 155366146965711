import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  Stack,
  Button,
  Popover,
  MenuList,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import logo from "../../assets/images/logo-white.png";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useData } from "../../providers/DataProvider";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

type Props = {
  headerRight?: React.ReactNode;
  title?: string;
};

const Header = ({ title = "Construction Bonus" }: Props) => {
  const { setRole, role } = useData();
  const { instance, accounts } = useMsal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRoleSelect = (role: string) => {
    setRole(role);
    localStorage.setItem("role", role);
    handleClose();
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "#003057",
      }}
    >
      <Toolbar>
        <Box display="flex" flex={1} justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <Link to="/">
              <img src={logo} height={60} alt="logo" />
            </Link>
            <Box
              mx={2}
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="h6" color="inherit">
                {title}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
            <Tooltip title="Change Role">
              <Box
                onClick={handleClick}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  color: "inherit",
                }}
              >
                <IconButton
                  color="inherit"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AccountCircleIcon fontSize="medium" />
                  <Typography
                    variant="body2"
                    component="span"
                    sx={{ textAlign: "center" }}
                  >
                    {role ? role : "Select Role"}
                  </Typography>
                </IconButton>
              </Box>
            </Tooltip>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuList>
                {accounts[0].idTokenClaims?.roles
                  ?.filter((r) => r !== "Admin")
                  ?.sort((a, b) => a.localeCompare(b))
                  ?.map((r, index) => (
                    <MenuItem
                      key={index}
                      value={r}
                      onClick={() => handleRoleSelect(r)}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#f4f4f4",
                        },
                      }}
                    >
                      {r}
                    </MenuItem>
                  ))}
              </MenuList>
            </Popover>
            <Button
              variant="text"
              sx={{
                color: (theme) => theme.palette.common.white,
                backgroundColor: "#003057",
                borderWidth: 0,
              }}
              onClick={() =>
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                })
              }
            >
              Logout
            </Button>
          </Stack>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
