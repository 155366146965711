import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  InputAdornment,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { BuilderInfo, JobDetailsProps } from "../../providers/types";
import AxiosInstance from "../../utils/axiosInstance";
import { useMsal } from "@azure/msal-react";

interface BuilderSplitModalProps {
  job: JobDetailsProps;
  jobBuilders: BuilderInfo[];
  allBuilders: {
    builderEmail: string;
    builderName: string;
    builderNumber: number;
  }[];
  active: boolean;
  setJobBuilders: React.Dispatch<React.SetStateAction<BuilderInfo[]>>;
  reloadBuilders: () => void;
}

const BuilderSplitModal: React.FC<BuilderSplitModalProps> = ({
  job,
  jobBuilders,
  allBuilders,
  active,
  reloadBuilders,
}) => {
  const { accounts } = useMsal();
  const [open, setOpen] = useState<boolean>(false);
  const [builderSplits, setBuilderSplits] = useState<BuilderInfo[]>([]);

  const handleOpen = () => {
    if (!active) {
      alert("Can't edit a job actively in review");
      return;
    }
    setOpen(true);
    setBuilderSplits([...jobBuilders]);
  };

  const handleClose = () => {
    setOpen(false);
    setBuilderSplits([]);
  };

  const handleAddBuilder = () => {
    const newBuilder: BuilderInfo = {
      builderId: 0,
      jobId: job.jobId,
      builderName: "",
      builderNumber: 0,
      builderEmail: "",
      split: 0,
      splitAmount: 0,
      createdOn: "",
      createdBy: "",
      updatedOn: "",
      updatedBy: "",
      isActive: false,
    };
    setBuilderSplits([...builderSplits, newBuilder]);
  };

  const handleBuilderChange = (index: number, builderNumber: number) => {
    const newBuilderSplits = builderSplits.map((builderSplit, idx) => {
      if (idx === index) {
        const selectedBuilder = allBuilders.find(
          (builder) => builder.builderNumber === builderNumber
        );
        // Map the selected builder's details to the builderSplit,
        // ensuring that all relevant information is updated correctly.
        return selectedBuilder
          ? {
              ...builderSplit,
              builderId: selectedBuilder.builderNumber, // Assuming builderId needs to be updated
              builderName: selectedBuilder.builderName,
              builderEmail: selectedBuilder.builderEmail,
              builderNumber: selectedBuilder.builderNumber,
            }
          : builderSplit;
      }
      return builderSplit;
    });
    setBuilderSplits(newBuilderSplits);
  };

  const handleSplitChange = (index: number, split: number) => {
    const newBuilderSplits = builderSplits.map((builderSplit, idx) =>
      idx === index
        ? { ...builderSplit, split: Math.max(0, split) }
        : builderSplit
    );
    setBuilderSplits(newBuilderSplits);
  };

  const handleContinue = () => {
    if (!Boolean(builderSplits.length)) {
      alert("Please select a builder");
      return;
    }
    const hasNoName = builderSplits.some(
      (builderSplit) => builderSplit.builderName === ""
    );
    if (hasNoName) {
      alert("Missing builder name");
      return;
    }

    const hasZeroSplit = builderSplits.some(
      (builderSplit) => builderSplit.split === 0
    );
    if (hasZeroSplit) {
      alert("All builders must have a split greater than 0%.");
      return;
    }

    const totalSplit = builderSplits.reduce((acc, curr) => acc + curr.split, 0);
    if (builderSplits.length && totalSplit !== 100) {
      alert("Total split must equal 100%.");
      return;
    }

    // Check for duplicate builder names
    const namesSet = new Set();
    const hasDuplicateNames = builderSplits.some((builder) => {
      if (namesSet.has(builder.builderName)) {
        return true;
      }
      namesSet.add(builder.builderName);
      return false;
    });

    if (hasDuplicateNames) {
      alert("Duplicate builder names detected. Each builder must be unique.");
      return;
    }

    AxiosInstance.post("/api/cba/builders/bulk", {
      JobId: job.jobId,
      IsUpdateByReviewer: false,
      UnusedBonusAmount: job.bonusDetail.bonus.unusedBonusAmount,
      UpdatedBy: accounts[0].username,
      Comments: "",
      BulkUpdateBuildersRequests: builderSplits.map((builder) => ({
        JobId: job.jobId,
        BuilderName: builder.builderName,
        BuilderEmail: builder.builderEmail,
        BuilderNumber: builder.builderNumber,
        Split: builder.split,
        SplitAmount:
          job.bonusDetail?.bonus?.closingBonusAmount && builder.split
            ? parseFloat(
                (
                  job.bonusDetail.bonus.closingBonusAmount *
                  (builder.split / 100)
                ).toFixed(2)
              )
            : 0,
        CreatedBy: accounts[0].username,
      })),
    })
      .then((res) => {
        handleClose();
        reloadBuilders();
        // Reload the page after successful save
        window.location.reload();
      })
      .catch((error) => {
        console.error("Failed to save builder splits:", error);
        alert("An error occurred while saving. Please try again.");
      })
      .finally(() => {
        reloadBuilders(); // It might be redundant to call this before reloading the page
      });
  };

  useEffect(() => {
    if (allBuilders.length > 0) {
      setBuilderSplits([...jobBuilders]); // Initialize with jobBuilders when allBuilders is ready
    }
  }, [allBuilders, jobBuilders]);

  return (
    <>
      <Button
        onClick={handleOpen}
        variant="text"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          textTransform: "none",
          textAlign: "left",
          color: "unset",
          mt: -0.5,
        }}
      >
        <Box p={1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography textAlign="center" variant="caption">
              Click to edit
            </Typography>
          </Box>

          <Typography>
            <strong>{`Builder: `}</strong>
            {jobBuilders.length
              ? jobBuilders.map((builder) => builder.builderName).join(", ")
              : "Unassigned"}
          </Typography>
          <Typography>
            <strong>{`Split: `}</strong>
            {jobBuilders.length
              ? jobBuilders.map((builder) => builder.split).join("/ ")
              : "Unassigned"}
          </Typography>
        </Box>
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Builder Split</DialogTitle>
        <DialogContent>
          <TableContainer
            component={Paper}
            sx={{
              my: 2,
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Builder</TableCell>
                  <TableCell>Split (%)</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {builderSplits.map((builderSplit, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel>Builder</InputLabel>
                            <Select
                              value={builderSplit.builderNumber}
                              onChange={(e) =>
                                handleBuilderChange(
                                  index,
                                  Number(e.target.value)
                                )
                              }
                              label="Builder"
                            >
                              {allBuilders.map((builder) => (
                                <MenuItem
                                  key={builder.builderEmail}
                                  value={builder.builderNumber}
                                >
                                  {`${builder.builderName} (${builder.builderEmail})`}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            label="Split"
                            type="number"
                            value={builderSplit.split}
                            onChange={(e) =>
                              handleSplitChange(
                                index,
                                parseInt(e.target.value, 10)
                              )
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                          <IconButton
                            onClick={() =>
                              setBuilderSplits(
                                builderSplits.filter((_, idx) => idx !== index)
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
                {builderSplits.length < allBuilders.length && (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <Button
                        onClick={handleAddBuilder}
                        color="secondary"
                        variant="contained"
                        startIcon={<AddIcon />}
                        fullWidth
                      >
                        Add Builder
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions
          sx={{
            p: 2,
          }}
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handleContinue}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BuilderSplitModal;
