import React from "react";
import {
  Stack,
  Checkbox,
  FormControlLabel,
  Divider,
  Typography,
  Box,
} from "@mui/material";
import {
  fileAttachmentsByTypesProps,
  JobDetailsProps,
} from "../../providers/types";

interface ChecklistProps {
  job: JobDetailsProps | undefined;
  selectChecklistItem: (file: fileAttachmentsByTypesProps) => void;
  selectedFile: fileAttachmentsByTypesProps | null;
}

export default function Checklist({
  job,
  selectChecklistItem,
  selectedFile,
}: ChecklistProps) {
  if (!job) return null;

  const requiredFiles = job.fileAttachmentsByTypes.filter(
    (file) => file.isRequired
  );
  const optionalFiles = job.fileAttachmentsByTypes.filter(
    (file) => !file.isRequired
  );

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "22rem",
        padding: 2,
        overflowY: "auto", // Enable vertical scrolling
        maxHeight: "calc(100vh - 64px)", // Adjust this value as needed
      }}
    >
      <Stack
        spacing={2}
        sx={{
          width: "100%",
          pb: 10,
        }}
      >
        {requiredFiles.map((file, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                name={file.fileAttachmentName}
                checked={Boolean(file.fileAttachments.length)}
              />
            }
            label={file.fileAttachmentName}
            sx={{
              backgroundColor:
                selectedFile?.fileAttachmentName === file.fileAttachmentName
                  ? "lightgray"
                  : "transparent",
            }}
            onClick={() => selectChecklistItem(file)}
          />
        ))}

        {optionalFiles.length > 0 && (
          <>
            <Divider />
            <Typography variant="subtitle1" color="textSecondary">
              Optional Items
            </Typography>
            {optionalFiles.map((file, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    name={file.fileAttachmentName}
                    checked={Boolean(file.fileAttachments.length)}
                  />
                }
                label={file.fileAttachmentName}
                sx={{
                  backgroundColor:
                    selectedFile?.fileAttachmentName === file.fileAttachmentName
                      ? "lightgray"
                      : "transparent",
                }}
                onClick={() => selectChecklistItem(file)}
              />
            ))}
          </>
        )}
      </Stack>
    </Box>
  );
}
