import React, { useState, useEffect } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import axiosInstance from "../../utils/axiosInstance";
import moment from "moment";
import { commentsProps } from "../../providers/types";

type Props = {
  jobId: number;
};

const Comments = ({ jobId }: Props) => {
  const [open, setOpen] = useState(false);
  const [comments, setComments] = React.useState<commentsProps[]>([]);

  const loadComments = (id: number) => {
    axiosInstance
      .get(`/api/cba/comments/${id}`)
      .then((res) => {
        setComments(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (jobId && open) {
      loadComments(jobId);
    }
  }, [jobId, open]);

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        maxWidth={"md"}
        buttonVariant="text"
        buttonColor="secondary"
        content={
          <>
            <DialogContent>
              {comments.length ? (
                <Stack
                  divider={<Divider />}
                  direction="column"
                  spacing={1}
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                  }}
                >
                  {comments.map((comment) => (
                    <Box
                      key={comment?.id}
                      sx={{
                        mb: 1,
                        mt: 1,
                        wordWrap: "normal",
                        wordBreak: "break-word",
                      }}
                    >
                      <Typography variant="body2" color="BlackText">
                        {comment?.createdBy}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="GrayText"
                        sx={{
                          fontSize: "06.rem",
                        }}
                      >
                        {comment?.fileAttachmentTypeName || "General Comment"}{" "}
                      </Typography>
                      <Box my={1}>
                        <Typography
                          variant="caption"
                          color="CaptionText"
                          sx={{
                            fontSize: "08.rem",
                            pl: 0.5,
                          }}
                        >
                          {comment?.comment}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="GrayText"
                          textAlign="right"
                        >
                          {moment(comment?.createdOn).format("LLL")}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Stack>
              ) : (
                <DialogContentText>No comments.</DialogContentText>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpen(false)}
                variant="outlined"
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </>
        }
        buttonText="Comments"
        title="Comments"
      />
    </>
  );
};

export default Comments;
