import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import {
  Box,
  Typography,
  Paper,
  Divider,
  Button,
  CircularProgress,
} from "@mui/material";
import FileIcon from "@mui/icons-material/FilePresentOutlined";
import CloseIcon from "@mui/icons-material/Close";
import {
  fileAttachmentsByTypesProps,
  fileAttachmentsProps,
  JobDetailsProps,
} from "../../providers/types";
import axiosInstance from "../../utils/axiosInstance";
import { useMsal } from "@azure/msal-react";

interface UploadZoneProps {
  job: JobDetailsProps;
  reloadJob: () => void;
  selectChecklistItem: (file: fileAttachmentsByTypesProps) => void;
  selectedFile: fileAttachmentsByTypesProps;
}

export default function UploadZone({
  job,
  reloadJob,
  selectChecklistItem,
  selectedFile,
}: UploadZoneProps) {
  const [files, setFiles] = useState<fileAttachmentsProps[]>([]);
  const [loading, setLoading] = useState(false);
  const { accounts } = useMsal();

  function sanitizeFileName(inputString: string): string {
    const extensionMatch = inputString.match(/\.[0-9a-z]+$/i);
    const extension = extensionMatch ? extensionMatch[0] : "";
    const baseName = inputString.replace(/\.[0-9a-z]+$/i, "");
    const sanitizedBaseName = baseName.replace(/[^a-zA-Z0-9_-]/g, "");
    return sanitizedBaseName + extension;
  }

  const onDrop = async (acceptedFiles: File[]) => {
    setLoading(true);

    for (const file of acceptedFiles) {
      try {
        const getLinkResponse = await axiosInstance.get(
          `api/cba/file-upload-url?jobId=${job?.jobId}&fileTypeId=${
            // @ts-ignore
            selectedFile?.fileAttachmentTypeId
          }&fileName=${sanitizeFileName(file.name)}`
        );

        const urlToAttach = getLinkResponse.data.url;

        const uploadResponse = await fetch(urlToAttach, {
          method: "PUT",
          headers: { "x-ms-blob-type": "BlockBlob" },
          body: file,
        });

        const url = uploadResponse.url;
        const start = url.indexOf("attachment/") + "attachment/".length;
        const end = url.indexOf("?", start);
        const AttachedFile = url.substring(start, end);

        if (!uploadResponse.ok) {
          throw new Error(`Upload failed: ${uploadResponse.statusText}`);
        }

        const metadataResponse = await axiosInstance.post(
          `api/cba/add-file-attachment-metadata`,
          {
            JobId: job?.jobId,
            // @ts-ignore
            FileAttachmentTypeId: selectedFile?.fileAttachmentTypeId,
            FileName: sanitizeFileName(file.name),
            AttachedFile: AttachedFile,
            UploadedBy: accounts[0].username,
          }
        );

        if (metadataResponse.status !== 200) {
          throw new Error("There was an error creating the attachment");
        }

        const newFile: fileAttachmentsProps = {
          ...metadataResponse.data,
          fileBlob: await fetchFileStream(
            metadataResponse.data.fileAttachmentId
          ),
        };

        setFiles((prevFiles) => [...prevFiles, newFile]);
      } catch (error) {
        console.error("Error handling file upload:", error);
        alert("Error uploading file");
      }
    }
    reloadJob();
    setLoading(false);
  };

  const handleRemoveFile = (fileToRemove: fileAttachmentsProps) => {
    axiosInstance
      .delete(`api/cba/file-attachments/${fileToRemove.fileAttachmentTypeId}`, {
        data: {
          FileAttachmentId: fileToRemove.fileAttachmentId,
          UpdatedBy: accounts[0].username,
        },
      })
      .then((res) => {
        setFiles((currentFiles) =>
          currentFiles.filter(
            (file) => file.fileAttachmentId !== fileToRemove.fileAttachmentId
          )
        );
        reloadJob();
      });
  };

  const fetchFileStream = async (fileAttachmentId: number) => {
    try {
      if (!fileAttachmentId) throw new Error();
      const response = await axiosInstance.get(
        `/api/cba/attachmentfile/${fileAttachmentId}`,
        {
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching file stream:", error);
      return null;
    }
  };

  const fetchFiles = useCallback(async () => {
    if (job && selectedFile) {
      const selectedFileData = job.fileAttachmentsByTypes.find(
        (item) =>
          item.fileAttachmentTypeId === selectedFile.fileAttachmentTypeId
      );

      if (selectedFileData) {
        const filesWithBlobs = await Promise.all(
          selectedFileData.fileAttachments.map(async (file) => {
            const fileBlob = await fetchFileStream(file.fileAttachmentId);
            return { ...file, fileBlob };
          })
        );
        setFiles(filesWithBlobs);
      }
    }
  }, [job, selectedFile]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  const active = useMemo(
    () =>
      Boolean(job.reviewStatus) === false ||
      job.reviewStatus.trim() === "Rejected" ||
      job.reviewStatus.trim() === "New",
    [job]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: !active,
    multiple: true,
    maxFiles: 5,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  const renderFilePreview = (file: fileAttachmentsProps) => {
    if (!file?.fileBlob) {
      return null;
    }

    const objectUrl = URL.createObjectURL(file.fileBlob);

    if (file?.fileName?.toLowerCase().endsWith(".pdf")) {
      return (
        <Box
          sx={{
            display: "flex",
            flex: 1,
            height: "100%",
            position: "relative",
          }}
        >
          <iframe
            src={objectUrl}
            width="100%"
            height="100%"
            title="pdf preview"
            style={{
              overflow: "hidden",
              overflowX: "hidden",
              overflowY: "hidden",
            }}
            scrolling="no"
          />
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              cursor: "pointer",
            }}
            onClick={() => window.open(objectUrl, "_blank")}
          />
        </Box>
      );
    } else {
      return (
        <img
          src={objectUrl}
          alt={file.fileName}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      );
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        py: 2,
        px: 10,
        borderRadius: 1,
      }}
      {...getRootProps()}
    >
      <Paper
        elevation={isDragActive ? 3 : 0}
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          width: "100%",
          backgroundColor: isDragActive ? "action.hover" : "",
          border: "2px dashed #D5D5D5",
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        {loading && <CircularProgress size={24} sx={{ mt: 2 }} />}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "auto",
            flexWrap: "wrap",
            gap: 1,
            mt: 2,
            justifyContent: "flex-start",
          }}
        >
          {files.map((file, index) => (
            <Box
              key={index}
              sx={{
                position: "relative",
                width: 200,
                height: 200,
                margin: 0.5,
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  borderRadius: "10px",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() =>
                  window.open(URL.createObjectURL(file.fileBlob), "_blank")
                }
              >
                {renderFilePreview(file)}

                {active && (
                  <CloseIcon
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      color: "white",
                      backgroundColor: "#0a5dc2",
                      borderRadius: "50%",
                      cursor: "pointer",
                      margin: "5px",
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleRemoveFile(file);
                    }}
                  />
                )}
              </Paper>
            </Box>
          ))}
        </Box>

        {active ? (
          <Typography
            variant="body1"
            color={isDragActive ? "primary.main" : "text.secondary"}
          >
            {isDragActive
              ? "Drop the files here ..."
              : "Drag 'n' drop some files here, or click to select files"}
          </Typography>
        ) : (
          <Typography color="red">
            Can not edit jobs in review/completed
          </Typography>
        )}
      </Paper>
      <Box
        sx={{
          display: "flex",
          p: 2,
          width: "100%",
          height: "auto",
          minHeight: "5rem",
          color: "#9D9D9D",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography>Formats accepted: PDF only</Typography>

        <Divider sx={{ width: "100%", mt: 2 }} />
        <Button
          disableElevation
          href="https://highlandhomescorp.sharepoint.com/sites/ConstructionManual/SitePages/Document-Library.aspx?OR=Teams-HL&CT=1712327408604&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI1MC8yNDAyMjkyNDUxNyIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D"
          target="_blank"
          sx={{
            mt: 2,
            backgroundColor: "transparent",
            border: "1px solid #CBCBCB",
            color: "#606260",
            textTransform: "capitalize",
          }}
          variant="outlined"
          startIcon={<FileIcon sx={{ color: "#08B839" }} />}
        >
          Download Sample Templates
        </Button>
      </Box>
    </Box>
  );
}
