/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Button,
  Typography,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useData } from "../../providers/DataProvider";

const FindProjectForm: React.FC = () => {
  const {
    communities2,
    allJobs,
    selectedCommunity,
    selectedJob,
    setSelectedCommunity,
    selectedRegion,
    setSelectedJob,
  } = useData();
  const [jobs, setJobs] = useState<any[]>(allJobs);

  useEffect(() => {
    const filteredJobs = allJobs
      .filter((job) => job.projectId === selectedCommunity?.projectId)
      ?.sort((a, b) => a.jobAddress.localeCompare(b.jobAddress));

    setJobs(filteredJobs);
  }, [selectedCommunity, allJobs]);

  useEffect(() => {
    if (selectedRegion) {
      setSelectedCommunity(null);
      setSelectedJob(null);
    }
  }, [selectedRegion]);

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 1,
          gap: 3,
          mt: -15,
        }}
      >
        <Box>
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
            }}
          >
            Find your project
          </Typography>
        </Box>
        <FormControl fullWidth>
          <InputLabel id="community-select-label">Community</InputLabel>
          <Select
            labelId="community-select-label"
            id="community-select"
            value={selectedCommunity?.subDivision}
            label="Community"
            onChange={(e) => {
              const newCommunityId = e.target.value;
              const selected = communities2.find(
                (c) => c.subDivision === newCommunityId
              );
              setSelectedCommunity(selected || null);
              setSelectedJob(null);
            }}
          >
            {communities2.length > 0 ? (
              communities2.map((community) => (
                <MenuItem
                  key={community.projectId}
                  value={community.subDivision}
                >
                  {`${community.subDivision} - ${community.projectNumber}`}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No communities in region</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="job-select-label">Job</InputLabel>
          <Select
            labelId="job-select-label"
            id="job-select"
            value={selectedJob?.jobId}
            disabled={!Boolean(selectedCommunity?.company)}
            label="Job"
            onChange={(e) => {
              const newJobId = e.target.value;
              const selectedJob = allJobs.find(
                (job) => job.jobId.toString() === newJobId
              );
              setSelectedJob(selectedJob || null);
            }}
          >
            {jobs.map((job) => (
              <MenuItem key={job.jobId} value={job.jobId.toString()}>
                {`${job.jobAddress} - ${job.jobNumber}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box>
          <Button
            type="submit"
            variant="contained"
            href={`/upload/create/${selectedJob?.jobId}`}
            disabled={!selectedCommunity || !selectedJob}
            fullWidth
          >
            Search
          </Button>
          <Box
            sx={{
              mt: 1,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Link to="/upload">
              <FormHelperText>Return to File Explorer?</FormHelperText>
            </Link>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default FindProjectForm;
