import React, { useState } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  Theme,
  DialogContent,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  DialogActions,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMsal } from "@azure/msal-react";
import { useData } from "../../providers/DataProvider";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const RoleModal = ({ open, setOpen }: Props) => {
  const { accounts } = useMsal();
  const { setRole } = useData();
  const [roleT, setRoleT] = useState<string>("");
  const handleClose = () => {
    setOpen(false);
  };

  const save = async () => {
    setRole(roleT);
    localStorage.setItem("role", roleT);
    handleClose();
  };

  return (
    <Dialog
      maxWidth={"sm"}
      title="Choose Role"
      open={open}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        })}
      >
        Select a role
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 10,
          color: "#ffff",
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box mt={4}>
          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              value={roleT}
              label="Role"
              onChange={(e) => {}}
              placeholder="select a role"
            >
              {accounts[0].idTokenClaims?.roles
                ?.filter((role) => role !== "Admin")
                ?.sort((a, b) => a.localeCompare(b))
                ?.map((role, index) => (
                  <MenuItem
                    key={index}
                    value={role}
                    onClick={(e) => setRoleT(role)}
                    sx={{ "&:hover": { backgroundColor: "#f4f4f4" } }}
                  >
                    {role}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              You have more than one role assigned. Please choose one.
            </FormHelperText>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={save}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoleModal;
