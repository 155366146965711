import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Chip,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import FolderImg from "../../assets/images/folder.png";
import axiosInstance from "../../utils/axiosInstance";
import { useData } from "../../providers/DataProvider";
import { monthNames } from "../../providers/types";
import FileModal from "../../modals/FileModal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
interface MainContentProps {
  current: {
    files: any[];
    type: string;
    prev: any;
  };
  setCurrent: React.Dispatch<
    React.SetStateAction<{
      files: any[];
      type: string;
      prev: any;
    }>
  >;
}

const MainContent: React.FC<MainContentProps> = ({ current, setCurrent }) => {
  const { role } = useData();
  const { companies, selectedCompany, selectedRegion } = useData();
  function handleClick(file: any) {
    if (!selectedCompany || !selectedRegion) return;
    if (current.type === "company") {
      axiosInstance
        .get(
          `/api/cba/communities-summary/${selectedCompany?.companyId}/${selectedRegion.regionId}`
        )
        .then((res) => {
          const data = res.data;
          const uniqueYears = new Set(data.map((item: any) => item.year));
          const uniqueYearsArray = Array.from(uniqueYears);
          //@ts-ignore
          const sortedUniqueYears = uniqueYearsArray.sort((a, b) => a - b);
          setCurrent({
            files: sortedUniqueYears,
            type: "year",
            prev: {
              ...current.prev,
              type: "company",
              files: current.files,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (current.type === "year") {
      axiosInstance
        .get(
          `/api/cba/communities-summary/${selectedCompany?.companyId}/${selectedRegion.regionId}/`
        )
        .then((res) => {
          const data = res.data;

          // Filter out the items where item.year does not match the file (year)
          const filteredData = data.filter(
            (item: { year: any }) => item.year === file
          );

          // Extract the unique months from the filtered data
          const uniqueMonthsNumbers = new Set(
            filteredData.map((item: { month: any }) => item.month)
          );

          // Convert the unique month numbers to month names
          const uniqueMonthsNames = Array.from(uniqueMonthsNumbers)
            ?.sort((a: any, b: any) => a - b)
            .map((monthNumber) => monthNames[(monthNumber as any) - 1]);

          setCurrent({
            files: uniqueMonthsNames,
            type: "month",
            prev: {
              ...current.prev,
              type: "year",
              files: current.files,
              year: file,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (current.type === "month") {
      const monthNumber = monthNames.indexOf(file) + 1;
      axiosInstance
        .get(
          `/api/cba/communities-summary/${selectedCompany?.companyId}/${selectedRegion.regionId}/${current.prev.year}/${monthNumber}/`
        )
        .then((res) => {
          const data = res.data;
          setCurrent({
            files: data,
            type: "job",
            prev: {
              ...current.prev,
              type: "month",
              month: monthNumber,
              files: current.files,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (current.type === "job") {
      axiosInstance
        .get(`/api/cba/jobs/${file.projectId}`)
        .then((res) => {
          const data = res.data;
          setCurrent({
            files: data.filter(
              (job: { month: any }) => job.month === current.prev.month
            ),
            type: "address",
            prev: {
              ...current.prev,
              type: "job",
              job: file.projectId,
              files: current.files,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (current.type === "address") {
      axiosInstance
        .get(`/api/cba/job-details/${file.jobId}`, {
          headers: {
            roleType: role,
          },
        })
        .then((res) => {
          const data = res.data;

          setCurrent({
            files: data?.fileAttachments,
            type: "file",
            prev: {
              ...current.prev,
              type: "address",
              files: current.files,
              jobId: file.jobId,
              job: data,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }

  useEffect(() => {
    if (companies || selectedCompany) {
      setCurrent({
        ...current,
        files: companies || [selectedCompany],
        type: "company",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies, selectedCompany]);

  const reviewStatus = current.prev?.job?.reviewStatus?.trim();

  const formatStatus = (status: string) => {
    const commonProps = {
      sx: {
        display: "flex",
        gap: 1,
      },
    };

    if (status === "Rejected") {
      return (
        <Box {...commonProps}>
          <ErrorOutlineIcon color="error" />
          <Typography>Rejected</Typography>
        </Box>
      );
    }

    if (status === "New") {
      return (
        <Box {...commonProps}>
          <CheckCircleOutlineIcon color="success" />
          <Typography>Ready to Start</Typography>
        </Box>
      );
    } else if (status === "InPMReview") {
      return (
        <Box {...commonProps}>
          <CheckCircleOutlineIcon color="success" />
          <Typography>Awaiting PM Review</Typography>
        </Box>
      );
    } else if (status === "InSPMReview") {
      return (
        <Box {...commonProps}>
          <CheckCircleOutlineIcon color="success" />
          <Typography>Awaiting SPM Review</Typography>
        </Box>
      );
    } else if (status === "InDMReview" || status === "InOpsReview") {
      return (
        <Box {...commonProps}>
          <CheckCircleOutlineIcon color="success" />
          <Typography>
            Awaiting {status === "InDMReview" ? "DM" : "Ops"} Review
          </Typography>
        </Box>
      );
    } else if (status === "Complete") {
      return (
        <Box {...commonProps}>
          <CheckCircleOutlineIcon color="success" />
          <Typography>Complete</Typography>
        </Box>
      );
    } else if (status === "Rejected") {
      return (
        <Box {...commonProps}>
          <ErrorOutlineIcon color="error" />
          <Typography>Rejected</Typography>
        </Box>
      );
    } else if (status === "Busted") {
      return (
        <Box {...commonProps}>
          <ErrorOutlineIcon color="error" />
          <Typography>Busted</Typography>
        </Box>
      );
    } else if (status === "Paid") {
      return (
        <Box {...commonProps}>
          <CheckCircleOutlineIcon color="success" />
          <Typography>Paid</Typography>
        </Box>
      );
    } else {
      return status;
    }
  };

  return (
    <Box
      sx={{
        p: 4,
        flexWrap: "wrap",
        width: "100%",
        height: "80vh",
        overflow: "scroll",
        "& > :not(style)": {
          marginBottom: 2,
        },
      }}
    >
      {current.type === "file" && (
        <Box ml={1}>{formatStatus(reviewStatus)}</Box>
      )}
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        gap={2}
        p={1}
      >
        {current.files?.map((file, index) => {
          switch (current.type) {
            case "company":
              return (
                <CardActionArea
                  key={index}
                  onClick={() => handleClick(file)}
                  sx={{
                    display: "flex",
                    height: "12rem",
                    width: "20rem",
                    borderRadius: "15px",
                  }}
                >
                  <Card
                    elevation={0}
                    sx={{
                      height: "12rem",
                      width: "20rem",
                      m: 0,
                      display: "flex",
                      borderRadius: "15px",
                      boxShadow: "0 2px 5px rgb(0 0 0 / 0.2)",
                    }}
                  >
                    <Box
                      flex={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      p={2.2}
                    >
                      <img src={FolderImg} alt="Folder" height={80} />
                    </Box>
                    <Box
                      flex={1}
                      pt={1}
                      pb={4}
                      px={1}
                      display="flex"
                      flexDirection="column"
                    >
                      <Box
                        sx={{
                          height: "2rem",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Chip label="Company" />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography variant="body1">
                          {file?.companyName || null}
                        </Typography>
                      </Box>
                    </Box>
                  </Card>
                </CardActionArea>
              );

            case "year":
              return (
                <CardActionArea
                  key={index}
                  onClick={() => handleClick(file)}
                  sx={{
                    display: "flex",
                    height: "12rem",
                    width: "20rem",
                    borderRadius: "15px",
                  }}
                >
                  <Card
                    elevation={0}
                    sx={{
                      height: "12rem",
                      width: "20rem",
                      m: 0,
                      display: "flex",
                      borderRadius: "15px",
                      boxShadow: "0 2px 5px rgb(0 0 0 / 0.2)",
                    }}
                  >
                    <Box
                      flex={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      p={2.2}
                    >
                      <img src={FolderImg} alt="Folder" height={80} />
                    </Box>
                    <Box
                      flex={1}
                      pt={1}
                      pb={4}
                      px={1}
                      display="flex"
                      flexDirection="column"
                    >
                      <Box
                        sx={{
                          height: "2rem",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Chip label="Year" />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography variant="body1">{file || null}</Typography>
                      </Box>
                    </Box>
                  </Card>
                </CardActionArea>
              );

            case "month":
              return (
                <CardActionArea
                  key={index}
                  onClick={() => handleClick(file)}
                  sx={{
                    display: "flex",
                    height: "12rem",
                    width: "20rem",
                    borderRadius: "15px",
                  }}
                >
                  <Card
                    elevation={0}
                    sx={{
                      height: "12rem",
                      width: "20rem",
                      m: 0,
                      display: "flex",
                      borderRadius: "15px",
                      boxShadow: "0 2px 5px rgb(0 0 0 / 0.2)",
                    }}
                  >
                    <Box
                      flex={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      p={2.2}
                    >
                      <img src={FolderImg} alt="Folder" height={80} />
                    </Box>
                    <Box
                      flex={1}
                      pt={1}
                      pb={4}
                      px={1}
                      display="flex"
                      flexDirection="column"
                    >
                      <Box
                        sx={{
                          height: "2rem",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Chip label="Month" />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography variant="body1">{file || null}</Typography>
                      </Box>
                    </Box>
                  </Card>
                </CardActionArea>
              );

            case "job":
              return (
                <>
                  <CardActionArea
                    key={index}
                    onClick={() => handleClick(file)}
                    sx={{
                      display: "flex",
                      height: "12rem",
                      width: "20rem",
                      borderRadius: "15px",
                    }}
                  >
                    <Card
                      elevation={0}
                      sx={{
                        height: "12rem",
                        width: "20rem",
                        m: 0,
                        display: "flex",
                        borderRadius: "15px",
                        boxShadow: "0 2px 5px rgb(0 0 0 / 0.2)",
                      }}
                    >
                      <Box
                        flex={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        p={2.2}
                      >
                        <img src={FolderImg} alt="Folder" height={80} />
                      </Box>
                      <Box
                        flex={1}
                        pt={1}
                        pb={4}
                        px={1}
                        display="flex"
                        flexDirection="column"
                      >
                        <Box
                          sx={{
                            height: "2rem",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Chip label="Community" />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="body1">
                            {`${file?.subDivision || ""} ${
                              file?.projectNumber
                                ? "-" + file?.projectNumber
                                : ""
                            }`}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </CardActionArea>
                </>
              );

            case "address":
              return (
                <CardActionArea
                  key={index}
                  onClick={() => handleClick(file)}
                  sx={{
                    display: "flex",
                    height: "12rem",
                    width: "20rem",
                    borderRadius: "15px",
                  }}
                >
                  <Card
                    elevation={0}
                    sx={{
                      height: "12rem",
                      width: "20rem",
                      m: 0,
                      display: "flex",
                      borderRadius: "15px",
                      boxShadow: "0 2px 5px rgb(0 0 0 / 0.2)",
                    }}
                  >
                    <Box
                      flex={1}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      p={2.2}
                    >
                      <img src={FolderImg} alt="Folder" height={80} />
                    </Box>
                    <Box
                      flex={1}
                      pt={1}
                      pb={4}
                      px={1}
                      display="flex"
                      flexDirection="column"
                    >
                      <Box
                        sx={{
                          height: "2rem",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Chip label="Address" />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography variant="body1">{`${
                          file?.jobAddress || ""
                        } - ${file?.projectNumber || ""}`}</Typography>
                      </Box>
                    </Box>
                  </Card>
                </CardActionArea>
              );

            case "file":
              return (
                <FileModal defaultIndex={index} key={index} current={current} />
              );

            default:
              return null;
          }
        })}

        {!current.files.length && current.type !== "file" && (
          <Box
            sx={{
              display: "flex",
              flex: 1,
              pt: 10,
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!current.files.length && current.type === "file" && (
          <Box
            sx={{
              display: "flex",
              pt: 10,
              flex: 1,
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                maxWidth: "20rem",
              }}
            >
              <Typography variant="body2" textAlign="center" mb={1}>
                No files uploaded to this job
              </Typography>
              <Button
                variant="contained"
                href={`/upload/create/${current.prev.jobId}`}
                sx={{
                  px: 6,
                  textTransform: "capitalize",
                }}
              >
                Upload Now
              </Button>
            </Box>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default MainContent;
