import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { JobProps } from "../../types";
import { useData } from "../../providers/DataProvider";
import { CommunitiesProps } from "../../providers/types";
import axiosInstance from "../../utils/axiosInstance";
interface SearchBarProps {
  current: {
    files: any[];
    type: string;
    prev: any;
  };
  setCurrent: React.Dispatch<
    React.SetStateAction<{
      files: any[];
      type: string;
      prev: any;
    }>
  >;
}

export default function SearchBar({ current, setCurrent }: SearchBarProps) {
  const { allJobs: jobs, communities, role } = useData();
  const [searchTerm, setSearchTerm] = useState("");
  const [jobResults, setJobResults] = useState<JobProps[]>([]);
  const [isFocused, setIsFocused] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);

    // For communities, ensuring unique entries by projectId
    const uniqueCommunities = new Map<number, CommunitiesProps>();
    communities.forEach((community) => {
      if (
        community?.subDivision?.toLowerCase()?.includes(value?.toLowerCase())
      ) {
        uniqueCommunities.set(community.projectId, community);
      }
    });

    // For jobs, filter based on the search term without checking uniqueness
    const filteredJobs = jobs.filter((job) =>
      job.jobAddress?.toLowerCase().includes(value?.toLowerCase())
    );

    setJobResults(filteredJobs as any);
  };

  const handleJobSelect = (job: JobProps) => {
    setSearchTerm("");
    axiosInstance
      .get(`/api/cba/job-details/${job.jobId}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setCurrent({
          ...current,
          files: response.data.fileAttachments,
          type: "file",
          prev: {
            jobId: job.jobId,
            job: response.data,
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
      });
  };

  const clearSearch = () => {
    setSearchTerm("");
    setIsFocused(false);
  };

  return (
    <Paper
      component="form"
      elevation={0}
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "400px",
        position: "relative",
      }}
      onSubmit={(e) => e.preventDefault()}
    >
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon fontSize="small" />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search..."
        size="small"
        value={searchTerm}
        onChange={handleSearchChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {Boolean(searchTerm.length) && (
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="clear"
          onClick={clearSearch}
          size="small"
        >
          <ClearIcon />
        </IconButton>
      )}
      {searchTerm && isFocused && (
        <List
          sx={{
            width: "100%",
            maxHeight: 300,
            position: "absolute",
            top: "100%",
            overflow: "auto",
            bgcolor: "background.paper",
            boxShadow: "0px 4px 12px rgba(0,0,0,0.15)",
            zIndex: 2000,
          }}
        >
          {jobResults.length > 0 ? (
            <>
              {/* <Box sx={{ flex: 1, p: 1, backgroundColor: "#EBF2FF", pl: 2 }}>
                <Typography sx={{ fontWeight: "bold" }}>Jobs</Typography>
              </Box> */}
              {jobResults.map((job, index) => (
                <ListItem
                  button
                  onMouseDown={() => handleJobSelect(job)}
                  key={index}
                >
                  <ListItemText
                    primary={`${job.jobAddress} - ${job.jobNumber}`}
                  />
                </ListItem>
              ))}
            </>
          ) : (
            <ListItem>
              <ListItemText primary="No job results found" />
            </ListItem>
          )}
        </List>
      )}
    </Paper>
  );
}
