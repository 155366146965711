import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: "#003057", // Primary color
      contrastText: "#FFFFFF", // Ensure text is readable on primary color
    },
    secondary: {
      main: "#EBF2FF", // Secondary color
      contrastText: "#003057", // Ensure text is readable on secondary color
    },
    error: {
      main: "#ff1744", // Error color
      contrastText: "#FFFFFF", // Ensure text is readable on error color
    },
    background: {
      default: "#f5f5f5", // Background color
      paper: "#FFFFFF", // Background for paper components like cards
    },
  },
  typography: {
    fontFamily: '"Lato", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "2.2rem",
      fontWeight: 500,
      color: "#003057", // Primary color for headings
    },
    h2: {
      fontSize: "1.8rem",
      fontWeight: 500,
      color: "#003057", // Primary color for subheadings
    },
    body1: {
      fontSize: "1rem",
      color: "#333333", // Default text color
    },
    button: {
      textTransform: "none", // Prevents all uppercase text on buttons
      fontWeight: 600, // Bolder text for buttons
    },
    // Add more styles as needed
  },
  spacing: 8, // Default spacing unit (e.g., 8px grid)
});

export default theme;
