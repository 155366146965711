import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  Theme,
  Paper,
  Grid,
  Button,
  Stack,
  Divider,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {
  commentsProps,
  fileAttachmentsProps,
  JobDetailsProps,
} from "../../providers/types";
import theme from "../../theme";
import axiosInstance from "../../utils/axiosInstance";
import moment from "moment";

interface Props {
  job?: JobDetailsProps;
  files: fileAttachmentsProps[];
  defaultIndex?: number;
}

const UploaderViewModal = ({ job, files, defaultIndex }: Props) => {
  const [open, setOpen] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(defaultIndex || 0);
  const [comments, setComments] = useState<commentsProps[]>([]);
  const [fileStreamUrl, setFileStreamUrl] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    if (files.length > 0) {
      fetchFileStream(files[currentFileIndex].fileAttachmentId);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setFileStreamUrl(undefined);
    if (fileStreamUrl) {
      URL.revokeObjectURL(fileStreamUrl); // Revoke the object URL to free up memory
    }
  };

  const handleNext = () => {
    if (files && currentFileIndex < files.length - 1) {
      setCurrentFileIndex(currentFileIndex + 1);
      fetchFileStream(files[currentFileIndex + 1].fileAttachmentId);
    }
  };

  const handlePrevious = () => {
    if (currentFileIndex > 0) {
      setCurrentFileIndex(currentFileIndex - 1);
      fetchFileStream(files[currentFileIndex - 1].fileAttachmentId);
    }
  };

  const fetchFileStream = (fileAttachmentId: number) => {
    setLoading(true);
    axiosInstance
      .get(`/api/cba/attachmentfile/${fileAttachmentId}`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setFileStreamUrl(url);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching file stream:", error);
        setLoading(false);
      });
  };

  const loadComments = async () => {
    if (job?.jobId) {
      axiosInstance.get(`/api/cba/comments/${job.jobId}`).then((response) => {
        setComments(response.data);
      });
    }
  };

  useEffect(() => {
    loadComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const isPdfFile = files[currentFileIndex]?.fileName
    .toLowerCase()
    .endsWith(".pdf");

  const getFileAttachmentTypeName = (fileAttachmentTypeId: number) => {
    return job?.fileAttachmentTypes.find(
      (type) => type.fileAttachmentTypesId === fileAttachmentTypeId
    )?.name;
  };

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        disableElevation
        onClick={handleOpen}
        startIcon={<QuestionAnswerIcon sx={{ color: "#0061FF" }} />}
      >
        <Typography color="#0061FF" sx={{ textTransform: "capitalize" }}>
          Comments
        </Typography>
      </Button>
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          sx: {
            maxHeight: "90vh", // Adjusted for better handling of the viewport height
            overflowY: "auto", // Ensures scrolling within the dialog if needed
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: (theme: Theme) => theme.palette.primary.main,
            color: theme.palette.common.white,
          }}
        >
          {`${job?.jobAddress} - ${job?.jobNumber}`}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.common.white,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={0} sx={{ height: "calc(90vh - 64px)" }}>
          <Grid item xs={9} sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
                backgroundColor: "#F5F5F5",
              }}
            >
              <IconButton
                onClick={handlePrevious}
                disabled={currentFileIndex === 0 || loading}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Typography
                variant="subtitle1"
                component="div"
                textAlign="center"
              >
                {files[currentFileIndex]
                  ? `${
                      getFileAttachmentTypeName(
                        files[currentFileIndex]?.fileAttachmentTypeId
                      ) || ""
                    } - ${files[currentFileIndex]?.fileName}`
                  : "No File"}
              </Typography>
              <IconButton
                onClick={handleNext}
                disabled={currentFileIndex === files.length - 1 || loading}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
            <Paper
              elevation={3}
              sx={{
                flexGrow: 1,
                overflow: "auto", // Changed to 'auto' to allow scrolling when needed
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : isPdfFile ? (
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    height: "100%",
                  }}
                >
                  <iframe
                    src={fileStreamUrl}
                    width="100%"
                    height="100%"
                    title="pdf preview"
                  />
                </Box>
              ) : (
                fileStreamUrl && (
                  <img
                    src={fileStreamUrl}
                    alt={files[currentFileIndex]?.fileName}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "70vh", // Set max height relative to view height
                      objectFit: "contain",
                    }}
                  />
                )
              )}
            </Paper>
          </Grid>
          <Grid
            item
            xs={3}
            display="flex"
            flexDirection="column"
            sx={{
              overflowY: "scroll",
            }}
          >
            <Stack
              direction="column"
              sx={{ p: 2, flex: 1, overflowY: "scroll" }}
              divider={<Divider flexItem />}
            >
              {Boolean(comments.length) ? (
                comments?.map((comment) => (
                  <Box
                    key={comment?.id}
                    sx={{
                      mb: 1,
                      mt: 1,
                      wordWrap: "normal",
                      wordBreak: "break-word",
                    }}
                  >
                    <Typography variant="body2" color="BlackText">
                      {comment?.createdBy}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="GrayText"
                      sx={{
                        fontSize: "06.rem",
                      }}
                    >
                      {comment?.fileAttachmentTypeName || "General Comment"}-
                      {comment?.fileAttachmentName}
                    </Typography>
                    <Box my={1}>
                      <Typography
                        variant="caption"
                        color="CaptionText"
                        sx={{
                          fontSize: "08.rem",
                          pl: 0.5,
                        }}
                      >
                        {comment?.comment}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        variant="caption"
                        color="GrayText"
                        textAlign="right"
                      >
                        {moment(comment?.createdOn).format("LLL")}
                      </Typography>
                    </Box>
                  </Box>
                ))
              ) : (
                <Box>
                  <Typography>No comments available</Typography>
                </Box>
              )}
            </Stack>
            <Box
              sx={{
                p: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                href={`/upload/create/${job?.jobId}`}
                fullWidth
              >
                Edit Files
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default UploaderViewModal;
