import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DownloadIcon from "@mui/icons-material/Download";
import Header from "../../components/Header";
import Copyright from "../../components/Copyright";
import RoleModal from "../../modals/RoleModal";
import { useMsal } from "@azure/msal-react";
import AxiosInstance from "../../utils/axiosInstance";
import { useData } from "../../providers/DataProvider";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { downloadFile } from "../../utils/downloadFile";
import { UIContext } from "../../providers/UIProvider";

const theme = createTheme();

const formatDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");

  return `${year}${month}${day}_${hours}${minutes}`;
};

type JobData = {
  jobId: number;
  areaID: number;
  areaName: string;
  projectNumber: string;
  subdivision: string;
  stage5Bonus: number;
  closingBonus: number;
  jobNumber: string;
  salesPrice: number;
  jobAddress: string;
  closeDate: string;
  stageCode: string;
  stage5Date: string;
  bonusStatusId: number;
  cMs: {
    employeeID: string;
    cm: string;
    bonusPercent: number;
    closingBonus: number;
  }[];
  notes: string;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: theme.palette.grey[200],
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
  },
}));

export default function Payroll() {
  const { role } = useData();
  const { accounts } = useMsal();
  const [state, dispatch]: any = React.useContext(UIContext);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingReport, setLoadingReport] = useState<boolean>(false);
  const [rows, setRows] = useState<JobData[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [fileModalOpen, setFileModalOpen] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const navigate = useNavigate();
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const isPayroll = accounts[0].idTokenClaims?.roles?.includes("Payroll");

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  const downloadReport = async () => {
    if (
      window.confirm(
        "Are you sure you want to submit the current payroll report? You will receive an automatic download."
      )
    ) {
      setLoadingReport(true);
      const formattedDate = formatDateTime();
      const filename = `CBA_Payout_${formattedDate}.xlsx`;
      await downloadFile(
        "/api/cba/payout-process/create-spreadsheet",
        filename
      );
      setLoadingReport(false);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
      setFileModalOpen(true);
    }
  };

  const handleCancelUpload = () => {
    setFile(null);
    setFileModalOpen(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleConfirmUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("File", file);

      setUploading(true);

      try {
        await AxiosInstance.post(
          "/api/cba/payout-process/upload-paid-info-spreadsheet",
          formData
        );
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: "File Uploaded Successfully",
            severity: "success",
          },
        });
        setFile(null);
        setFileModalOpen(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } catch (error) {
        alert("Failed to upload file.");
      } finally {
        setUploading(false);
      }
    }
  };

  const loadTable = async () => {
    setLoading(true);
    const res = await AxiosInstance.get("/api/cba/payout-details", {
      headers: {
        roleType: role,
      },
    });

    if (res && res.data) {
      setRows(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadTable();
  }, []);

  useEffect(() => {
    if (accounts[0].idTokenClaims?.roles && !isPayroll) {
      navigate("/");
    }
  }, [isPayroll, accounts, navigate]);

  return (
    <ThemeProvider theme={theme}>
      <Header title="Payroll Report" />
      <Container component="main" maxWidth={false}>
        <CssBaseline />
        <Box
          width={"100%"}
          sx={{
            mt: (theme) => theme.spacing(12),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              py: (theme) => theme.spacing(1),
              my: (theme) => theme.spacing(1.5),
            }}
          >
            <Button
              variant="contained"
              component="label"
              sx={{ mr: 2, backgroundColor: "#003057" }}
              startIcon={<UploadFileIcon />}
              disabled={uploading}
            >
              {uploading ? "Uploading..." : "Upload Report"}
              <input
                type="file"
                hidden
                accept=".xlsx"
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            </Button>

            <Button
              sx={{ backgroundColor: "#003057" }}
              variant="contained"
              onClick={downloadReport}
              disabled={!!!rows.length}
              startIcon={
                loadingReport ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <DownloadIcon />
                )
              }
            >
              Download Report
            </Button>
          </Box>

          <TableContainer
            component={Paper}
            sx={{
              flexGrow: 1,
              overflow: "auto",
              boxShadow: theme.shadows[4],
              borderRadius: 2,
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Job Number</StyledTableCell>
                  <StyledTableCell>Employee ID</StyledTableCell>
                  <StyledTableCell>CM Name</StyledTableCell>
                  <StyledTableCell>Closing Bonus</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : rows.length > 0 ? (
                  rows.map((row) => (
                    <React.Fragment key={row.jobId}>
                      {row.cMs.map((cm, index) => (
                        <StyledTableRow key={cm.employeeID}>
                          {index === 0 && (
                            <StyledTableCell rowSpan={row.cMs.length}>
                              {row.jobNumber}
                            </StyledTableCell>
                          )}
                          <StyledTableCell>{cm.employeeID}</StyledTableCell>
                          <StyledTableCell>{cm.cm}</StyledTableCell>
                          <StyledTableCell>
                            {formatCurrency(cm.closingBonus)}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </React.Fragment>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Box sx={{ py: "20dvh" }}>
                        <Typography variant="h6" align="center">
                          No items in payroll report.
                        </Typography>
                        <Typography
                          variant="body2"
                          align="center"
                          color="textSecondary"
                        >
                          Please approve more projects before returning.
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Copyright />
        <RoleModal open={open} setOpen={setOpen} />

        <Dialog open={fileModalOpen} onClose={handleCancelUpload}>
          <DialogTitle>Confirm File Upload</DialogTitle>
          <DialogContent dividers>
            <DialogContentText sx={{ mb: 2 }}>
              You have selected the file: <strong>{file?.name}</strong>
            </DialogContentText>
            <DialogContentText variant="body2" color="textSecondary">
              Please confirm if you want to upload this file or cancel to select
              a different one.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button onClick={handleCancelUpload} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleConfirmUpload}
              color="primary"
              variant="contained"
              disabled={uploading}
            >
              {uploading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Confirm Upload"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </ThemeProvider>
  );
}
