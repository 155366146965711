import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "@mui/material";

interface SignInButtonProps {
  variant?: "contained" | "outlined";
}

export const SignInButton: React.FC = ({ variant }: SignInButtonProps) => {
  const { instance } = useMsal();

  const handleLogin = (loginType: string) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e: any) => {});
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e: any) => {});
    }
  };
  return (
    <Button
      variant={variant || "outlined"}
      sx={{ color: (theme) => theme.palette.common.white }}
      onClick={() => handleLogin("redirect")}
    >
      Sign In
    </Button>
  );
};
