import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  InputAdornment,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useData } from "../../providers/DataProvider";
import { BuilderInfo, JobDetailsProps } from "../../providers/types";
import AxiosInstance from "../../utils/axiosInstance";
import { useMsal } from "@azure/msal-react";
import PaymentsIcon from "@mui/icons-material/Payments";

interface bonusHistory {
  bonusHistoryId: number;
  comments: string;
  builders: any[];
  bonusId: number;
  jobId: number;
  salesPrice: number;
  closingBonusAmount: number;
  stage5BonusAmount: number;
  unusedBonusAmount: number;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
}

interface BuilderSplitModalProps {
  jobId: number;
}

const BuilderSplitModal: React.FC<BuilderSplitModalProps> = ({ jobId }) => {
  const { role } = useData();
  const { accounts } = useMsal();
  const [open, setOpen] = useState<boolean>(false);
  const [builderSplits, setBuilderSplits] = useState<BuilderInfo[]>([]);
  const [job, setJob] = useState<JobDetailsProps | undefined>(undefined);
  const [allBuilders, setAllBuilders] = useState<
    {
      builderEmail: string;
      builderName: string;
      builderNumber: number;
    }[]
  >([]);
  const [comments, setComments] = useState<string>("");
  const [unusedBonusAmount, setUnusedBonusAmount] = useState<number>(0);
  const [isReadyOnly, setIsReadyOnly] = useState<boolean>(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setBuilderSplits([]);
  };

  const handleAddBuilder = useCallback(() => {
    setBuilderSplits((prev) => [
      ...prev,
      {
        builderId: 0,
        jobId,
        builderName: "",
        builderNumber: 0,
        builderEmail: "",
        split: 0,
        splitAmount: 0,
        createdOn: "",
        createdBy: "",
        updatedOn: "",
        updatedBy: "",
        isActive: false,
      },
    ]);
  }, [jobId]);

  const handleBuilderChange = useCallback(
    (index: number, builderNumber: number) => {
      setBuilderSplits((prev) =>
        prev.map((builderSplit, idx) => {
          if (idx === index) {
            const selectedBuilder = allBuilders.find(
              (builder) => builder.builderNumber === builderNumber
            );
            return selectedBuilder
              ? {
                  ...builderSplit,
                  builderId: selectedBuilder.builderNumber,
                  builderName: selectedBuilder.builderName,
                  builderEmail: selectedBuilder.builderEmail,
                  builderNumber: selectedBuilder.builderNumber,
                }
              : builderSplit;
          }
          return builderSplit;
        })
      );
    },
    [allBuilders]
  );

  const handleSplitChange = useCallback(
    (index: number, split: number) => {
      const closingBonusAmount =
        (job?.bonusDetail.bonus.closingBonusAmount || 0) - unusedBonusAmount;
      setBuilderSplits((prev) =>
        prev.map((builderSplit, idx) =>
          idx === index
            ? {
                ...builderSplit,
                split: Math.max(0, split),
                splitAmount: parseFloat(
                  (closingBonusAmount * (split / 100)).toFixed(2)
                ),
              }
            : builderSplit
        )
      );
    },
    [job, unusedBonusAmount]
  );

  const handleBonusAmountChange = useCallback(
    (index: number, splitAmount: number) => {
      const closingBonusAmount =
        (job?.bonusDetail.bonus.closingBonusAmount || 0) - unusedBonusAmount;
      const totalSplitAmount = builderSplits.reduce(
        (total, builderSplit, idx) => {
          return (
            total + (idx === index ? splitAmount : builderSplit.splitAmount)
          );
        },
        0
      );

      if (totalSplitAmount > closingBonusAmount) {
        alert("Total bonus amount cannot exceed the closing bonus amount.");
        return;
      }

      setBuilderSplits((prev) =>
        prev.map((builderSplit, idx) =>
          idx === index
            ? { ...builderSplit, splitAmount: Math.max(0, splitAmount) }
            : builderSplit
        )
      );
    },
    [builderSplits, job, unusedBonusAmount]
  );

  const handleUnusedBonusChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(e.target.value) || 0;
      if (job && value > job.bonusDetail.bonus.closingBonusAmount) {
        alert(
          "Unused bonus amount cannot be greater than the closing bonus amount."
        );
        setUnusedBonusAmount(job.bonusDetail.bonus.closingBonusAmount);
      } else {
        setUnusedBonusAmount(value);
      }
    },
    [job]
  );

  const handleContinue = useCallback(() => {
    if (!builderSplits.length) {
      alert("Please select a builder");
      return;
    }
    if (builderSplits.some((builderSplit) => !builderSplit.builderName)) {
      alert("Missing builder name");
      return;
    }
    if (builderSplits.some((builderSplit) => builderSplit.split === 0)) {
      alert("All builders must have a split greater than 0%.");
      return;
    }
    if (builderSplits.reduce((acc, curr) => acc + curr.split, 0) !== 100) {
      alert("Total split must equal 100%.");
      return;
    }
    if (
      builderSplits.reduce(
        (total, builderSplit) => total + builderSplit.splitAmount,
        0
      ) +
        unusedBonusAmount >
      job?.bonusDetail.bonus.closingBonusAmount
    ) {
      alert(
        "Total bonus amount including unused bonus cannot exceed the closing bonus amount."
      );
      return;
    }
    if (!comments.trim()) {
      alert("Comments are required");
      return;
    }

    AxiosInstance.post("/api/cba/builders/bulk", {
      JobId: jobId,
      UnusedBonusAmount: unusedBonusAmount,
      UpdatedBy: accounts[0].username,
      Comments: comments,
      BulkUpdateBuildersRequests: builderSplits.map((builder) => ({
        JobId: jobId,
        BuilderName: builder.builderName,
        BuilderEmail: builder.builderEmail,
        BuilderNumber: builder.builderNumber,
        Split: builder.split,
        SplitAmount: builder.splitAmount,
        CreatedBy: accounts[0].username,
      })),
    })
      .then(() => {
        handleClose();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Failed to save builder splits:", error);
        alert("An error occurred while saving. Please try again.");
      });
  }, [
    accounts,
    builderSplits,
    comments,
    handleClose,
    job,
    jobId,
    unusedBonusAmount,
  ]);

  const loadAllBuilders = useCallback(() => {
    AxiosInstance.post(`/api/cba/builders/search`)
      .then((response) => setAllBuilders(response.data))
      .catch((error) =>
        console.error("Error fetching job builder details:", error)
      );
  }, []);

  const loadJob = useCallback(() => {
    AxiosInstance.get(`/api/cba/job-details/${jobId}`, {
      headers: {
        roleType: role,
      },
    })
      .then((response) => {
        setJob(response.data);
        setUnusedBonusAmount(response.data.bonusDetail.bonus.unusedBonusAmount);
        setIsReadyOnly(response.data.bonusDetail.isReadyOnly);
        if (!response.data.bonusDetail.isReadyOnly) {
          loadAllBuilders();
        }
        if (response.data.isDefaultSplitAmount) {
          setBuilderSplits(
            response.data.builders.map(
              (builder: { split: any; splitAmount: number }) => {
                const closingNumberAmount =
                  response.data.BonusDetail.bonus.closingBonusAmount;
                const unusedBonusAmount =
                  response.data.BonusDetail.bonus.unusedBonusAmount;
                builder.splitAmount =
                  (closingNumberAmount - unusedBonusAmount) *
                  builder.split *
                  0.01;
                return builder;
              }
            )
          );
        } else {
          setBuilderSplits(response.data.builders);
        }
      })
      .catch((error) => console.error("Error fetching job details:", error));
  }, [jobId, loadAllBuilders, role]);

  useEffect(() => {
    if (open) {
      loadJob();
    }
  }, [open, loadJob]);

  useEffect(() => {
    if (job) {
      const closingBonusAmount =
        (job.bonusDetail.bonus.closingBonusAmount || 0) - unusedBonusAmount;
      setBuilderSplits((prev) =>
        prev.map((builderSplit) => ({
          ...builderSplit,
          splitAmount: parseFloat(
            (closingBonusAmount * (builderSplit.split / 100)).toFixed(2)
          ),
        }))
      );
    }
  }, [job, unusedBonusAmount]);

  useEffect(() => {
    if (
      builderSplits.length > 0 &&
      builderSplits.every((builderSplit) => builderSplit.splitAmount === 0)
    ) {
      const closingBonusAmount =
        (job?.bonusDetail.bonus.closingBonusAmount || 0) - unusedBonusAmount;
      setBuilderSplits((prev) =>
        prev.map((builderSplit) => ({
          ...builderSplit,
          splitAmount: parseFloat(
            (closingBonusAmount * (builderSplit.split / 100)).toFixed(2)
          ),
        }))
      );
    }
  }, [builderSplits, job, unusedBonusAmount]);

  return (
    <>
      <Tooltip title={"Builder Split"}>
        <IconButton onClick={handleOpen}>
          <PaymentsIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{ sx: { minHeight: "30vh", maxHeight: "80vh" } }}
      >
        <DialogTitle>Builder Split</DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} sx={{ my: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Builder</TableCell>
                  <TableCell>Split (%)</TableCell>
                  <TableCell>Bonus Amount</TableCell>
                  {!isReadyOnly && <TableCell>Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {builderSplits.map((builderSplit, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {isReadyOnly ? (
                        <>
                          <Typography>{builderSplit.builderName}</Typography>
                          <Typography variant="body2">
                            {builderSplit.builderEmail}
                          </Typography>
                        </>
                      ) : (
                        <FormControl fullWidth>
                          <InputLabel>Builder</InputLabel>
                          <Select
                            value={builderSplit.builderNumber}
                            onChange={(e) =>
                              handleBuilderChange(index, Number(e.target.value))
                            }
                            label="Builder"
                          >
                            {allBuilders.map((builder) => (
                              <MenuItem
                                key={builder.builderEmail}
                                value={builder.builderNumber}
                              >
                                {`${builder.builderName} (${builder.builderEmail})`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Split"
                        type="number"
                        value={builderSplit.split}
                        onChange={(e) =>
                          handleSplitChange(index, parseInt(e.target.value, 10))
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          readOnly: isReadyOnly,
                        }}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        label="Bonus Amount"
                        type="number"
                        value={builderSplit.splitAmount.toFixed(2)}
                        onChange={(e) =>
                          handleBonusAmountChange(
                            index,
                            parseFloat(e.target.value)
                          )
                        }
                        fullWidth
                        InputProps={{
                          readOnly: isReadyOnly,
                        }}
                      />
                    </TableCell>
                    {!isReadyOnly && (
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            setBuilderSplits((prev) =>
                              prev.filter((_, idx) => idx !== index)
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {builderSplits.length < allBuilders.length && !isReadyOnly && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <Button
                        onClick={handleAddBuilder}
                        variant="contained"
                        startIcon={<AddIcon />}
                        fullWidth
                      >
                        Add Builder
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={2}>
              <Stack direction="column" spacing={1} sx={{ mt: 2 }}>
                <TextField
                  label="Closing Bonus"
                  variant="standard"
                  sx={{ mr: 1 }}
                  value={job?.bonusDetail.bonus.closingBonusAmount.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  )}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Stage 5 Bonus"
                  variant="standard"
                  value={job?.bonusDetail.bonus.stage5BonusAmount.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  )}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Unused Bonus"
                  variant="standard"
                  value={unusedBonusAmount}
                  onChange={handleUnusedBonusChange}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputProps: {
                      step: "0.01",
                      min: "0",
                    },
                    readOnly: isReadyOnly,
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
            </Grid>
            <Grid item xs={10}>
              <TextField
                required
                label="Comment"
                multiline
                rows={6.5}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                fullWidth
                InputProps={{
                  readOnly: isReadyOnly,
                }}
              />
            </Grid>
          </Grid>

          <Accordion defaultExpanded sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Split History</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="column" divider={<Divider />} spacing={2}>
                {job?.bonusDetail?.bonusHistory
                  .sort(
                    (
                      a: { updatedOn: string | number | Date },
                      b: { updatedOn: string | number | Date }
                    ) =>
                      new Date(b.updatedOn).getTime() -
                      new Date(a.updatedOn).getTime()
                  )
                  .map((bonusDetail: bonusHistory) => (
                    <Paper
                      key={bonusDetail.bonusHistoryId}
                      elevation={3}
                      style={{ padding: "16px", marginBottom: "16px" }}
                    >
                      <Typography variant="body2">
                        Updated by {bonusDetail.updatedBy} on{" "}
                        {new Date(bonusDetail.updatedOn).toLocaleString()}
                      </Typography>
                      <Typography variant="body2">
                        Closing Bonus $
                        {bonusDetail.closingBonusAmount.toLocaleString()}
                      </Typography>
                      <Typography variant="body2">
                        Stage5 Bonus $
                        {bonusDetail.stage5BonusAmount.toLocaleString()}
                      </Typography>
                      <Typography variant="body2">
                        Unused Bonus $
                        {bonusDetail.unusedBonusAmount.toLocaleString()}
                      </Typography>
                      <Typography variant="body2" style={{ marginTop: "8px" }}>
                        Comments: {bonusDetail.comments}
                      </Typography>
                      <TableContainer
                        component={Paper}
                        elevation={0}
                        style={{ marginTop: "16px" }}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Builder Name</TableCell>
                              <TableCell>Builder Email</TableCell>
                              <TableCell>Split</TableCell>
                              <TableCell>Split Amount</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {bonusDetail.builders.map((builder, index) => (
                              <TableRow key={index}>
                                <TableCell>{builder.builderName}</TableCell>
                                <TableCell>{builder.builderEmail}</TableCell>
                                <TableCell>{builder.split}</TableCell>
                                <TableCell>
                                  ${builder.splitAmount.toLocaleString()}
                                </TableCell>
                                <TableCell>{builder.action}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  ))}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions
          sx={{
            p: 2,
          }}
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={handleContinue}
            disabled={isReadyOnly}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BuilderSplitModal;
