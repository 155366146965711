/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Box,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";
import { useData } from "../../providers/DataProvider";
import FindProjectForm from "../../components/FindProjectForm";
import TitleAndRegion from "../../components/TitleAndRegion";
import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

export default function FilUploadSearch() {
  const { instance, accounts } = useMsal();
  const { selectedCompany, selectedRegion, loadCommunities } = useData();

  useEffect(() => {
    if (selectedCompany && selectedRegion) {
      loadCommunities();
    }
  }, [selectedCompany, selectedRegion]);

  return (
    <>
      <Container
        component="main"
        disableGutters
        maxWidth={false}
        sx={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          flexDirection: "column",
          backgroundColor: "#F5F5F5",
          overflow: "hidden",
          padding: 0,
          margin: 0,
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TitleAndRegion logo={true} title="File Upload" />
          <Box
            sx={{
              p: 3,
            }}
          >
            <Button
              variant="contained"
              sx={{
                color: (theme) => theme.palette.common.white,
              }}
              onClick={() =>
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                })
              }
            >
              Logout
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
          }}
        >
          <FindProjectForm />
        </Box>
      </Container>
    </>
  );
}
