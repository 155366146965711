import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Typography,
  SelectChangeEvent,
  FormHelperText,
  Stack,
  Chip,
  Button,
} from "@mui/material";
import Header from "../../components/Header";
import axiosInstance from "../../utils/axiosInstance";
import Copyright from "../../components/Copyright";
import { useData } from "../../providers/DataProvider";
import { ProjectsProps } from "../../providers/types";
import { useContext, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import RoleModal from "../../modals/RoleModal";
import CheckCircleOutlineIcon from "@mui/icons-material/Check";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import { UIContext } from "../../providers/UIProvider";
import { useNavigate } from "react-router-dom";
const theme = createTheme();

export default function Admin() {
  const [state, dispatch]: any = useContext(UIContext);
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const { loadProjects, projects } = useData();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectOpen, setSelectOpen] = React.useState(false);
  const [selectedProjectId, setSelectedProjectId] = React.useState<
    number | undefined
  >(undefined);
  const [selectedProject, setSelectedProject] = React.useState<
    ProjectsProps | undefined
  >(undefined);
  const [selectedSteps, setSelectedSteps] = React.useState<{
    pm: boolean;
    spm: boolean;
  }>({
    pm: false,
    spm: false,
  });

  const isAdmin = accounts[0].idTokenClaims?.roles?.includes("Admin");

  useEffect(() => {
    if (accounts[0].idTokenClaims?.roles && !isAdmin) {
      navigate("/");
    }
  }, [isAdmin, accounts, navigate]);

  useEffect(() => {
    loadProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    if (!selectedProject) {
      alert("Please select a project");
      return;
    }

    // const skipReviewSteps = [];
    // if (selectedSteps.pm) {
    //   skipReviewSteps.push({
    //     ProjectId: selectedProject.projectId,
    //     ReviewStepId: 2,
    //     CreatedBy: accounts[0].username,
    //   });
    // }
    // if (selectedSteps.spm) {
    //   skipReviewSteps.push({
    //     ProjectId: selectedProject.projectId,
    //     ReviewStepId: 3,
    //     CreatedBy: accounts[0].username,
    //   });
    // }

    axiosInstance
      .post("/api/cba/UpdateSkipReviews", {
        ProjectId: selectedProject.projectId,
        CreatedBy: accounts[0].username,
        IsPmSkipped: selectedSteps.pm,
        IsSPmSkipped: selectedSteps.spm,
      })
      .then((response) => {
        setSelectedProject(undefined);
        setSelectedProjectId(undefined);
        setSelectedSteps({
          pm: false,
          spm: false,
        });
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: "Successfully skipped review steps",
            severity: "success",
          },
        });
        //window.location.reload();
      })
      .then(() => {
        loadProjects();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleChange = (event: SelectChangeEvent<number>) => {
    const project = projects.find((p) => p.projectId === event.target.value);
    setSelectedProjectId(Number(event.target.value));

    setSelectedProject(project);
    setSelectedSteps({
      pm: project?.isPmSkipped as boolean,
      spm: project?.isSpmSkipped as boolean,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Header title="Admin Portal" />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          width={"100%"}
          sx={{
            mt: (theme) => theme.spacing(10),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "75vh",
              width: "100%",
              mt: -8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box mb={4}>
              <Typography
                variant="h4"
                textAlign="center"
                sx={{
                  fontFamily: "Lato",
                }}
              >
                Skip Review
              </Typography>
            </Box>
            <FormControl fullWidth>
              <InputLabel id="project-select-label">Project</InputLabel>
              <Select
                labelId="project-select-label"
                id="project-select"
                value={selectedProjectId || ""}
                label="Project"
                onChange={handleChange}
                onOpen={() => setSelectOpen(true)}
                onClose={() => setSelectOpen(false)}
              >
                {projects.map((project) => {
                  const isUsed = project.skipReviews.length >= 2;

                  return (
                    <MenuItem key={project.projectId} value={project.projectId}>
                      {`${project.subdivision} - ${project.projectNumber}`}
                      {isUsed && selectOpen && (
                        <CheckCircleOutlineIcon
                          style={{
                            marginLeft: "auto",
                            color: theme.palette.primary.main,
                          }}
                        />
                      )}
                    </MenuItem>
                  );
                })}
              </Select>

              <FormHelperText>
                Select a project. Then select the review steps you want to
                update.
              </FormHelperText>
            </FormControl>
            {selectedProject && (
              <Box mt={4}>
                <Stack
                  my={2}
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                >
                  <Box>
                    <Box>
                      <Chip
                        color="primary"
                        variant={selectedSteps.pm ? "filled" : "outlined"}
                        icon={
                          selectedSteps.pm ? (
                            <CheckCircleOutlineIcon />
                          ) : (
                            <UnpublishedIcon />
                          )
                        }
                        onClick={() =>
                          setSelectedSteps({
                            ...selectedSteps,
                            pm: !selectedSteps.pm,
                          })
                        }
                        label="PM"
                      />
                    </Box>
                  </Box>
                  <Box>
                    <Chip
                      color="primary"
                      variant={selectedSteps.spm ? "filled" : "outlined"}
                      onClick={() =>
                        setSelectedSteps({
                          ...selectedSteps,
                          spm: !selectedSteps.spm,
                        })
                      }
                      icon={
                        selectedSteps.spm ? (
                          <CheckCircleOutlineIcon />
                        ) : (
                          <UnpublishedIcon />
                        )
                      }
                      label="SPM"
                    />
                  </Box>
                </Stack>
                <Box
                  my={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button fullWidth variant="contained" onClick={handleSubmit}>
                    Save{" "}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Copyright />
        <RoleModal open={open} setOpen={setOpen} />
      </Container>
    </ThemeProvider>
  );
}
