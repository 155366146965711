import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot
import App from "./App.jsx";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { BrowserRouter } from "react-router-dom";
import { UIContextProvider } from "./providers/UIProvider";
import SnackbarAlert from "./components/snackbarAlert";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
// @ts-ignore
export const msalInstance = new PublicClientApplication(msalConfig);

// Get the container DOM element to pass to createRoot
const container = document.getElementById("root");
// @ts-ignore
const root = createRoot(container); // Create a root.

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <UIContextProvider>
          <SnackbarAlert />
          <App />
        </UIContextProvider>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
);
