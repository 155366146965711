/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  Container,
  Box,
  Backdrop,
  CircularProgress,
  AppBar,
  Button,
  Toolbar,
  Typography,
} from "@mui/material";
import TitleAndRegion from "../../components/TitleAndRegion";
import Checklist from "../../components/Checklist";
import UploadZone from "../../components/UploadZone";
import axiosInstance from "../../utils/axiosInstance";
import { useData } from "../../providers/DataProvider";
import {
  BuilderInfo,
  fileAttachmentsByTypesProps,
  JobDetailsProps,
} from "../../providers/types";
import BuilderSplitModal from "../../components/BuilderSplitModal";
import { useMsal } from "@azure/msal-react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Comments from "../../modals/Comments";

export default function FilUploadList() {
  const { role } = useData();
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [job, setJob] = React.useState<JobDetailsProps | undefined>(undefined);
  const [jobBuilders, setJobBuilders] = React.useState<BuilderInfo[]>([]);
  const [ableToEdit, setAbletoEdit] = React.useState<boolean>(false);
  const [ableToEditBuilder, setAbletoEditBuilder] =
    React.useState<boolean>(false);
  const [allBuilders, setAllBuilders] = React.useState<
    {
      builderEmail: string;
      builderName: string;
      builderNumber: number;
    }[]
  >([]);
  const [selectedFile, setSelectedFile] =
    React.useState<fileAttachmentsByTypesProps | null>(null);
  const [readyToSubmit, setReadyToSubmit] = React.useState(false);

  const { id } = useParams();

  const selectChecklistItem = (file: fileAttachmentsByTypesProps) => {
    setSelectedFile(file);
  };

  const submitJob = () => {
    if (selectedFile && job) {
      const requiredAttachments = job.fileAttachmentsByTypes.filter(
        (item) => item.isRequired
      );
      const allHaveAttachments = requiredAttachments.every(
        (item) => item.fileAttachments.length > 0
      );

      if (allHaveAttachments) {
        if (!jobBuilders.length) {
          alert("Please assign a builder");
          return;
        }
        if (window.confirm("Are you sure you want to submit for review?")) {
          axiosInstance
            .put(`/api/cba/bonuses/${job.jobId}`, {
              JobId: job.jobId,
              BonusStatus: "Review",
              UpdatedBy: accounts[0].username,
            })
            .then(() => {
              navigate("/upload");
            });
        }
      } else {
        alert("Please add all required attachments before submitting");
      }
    }
  };

  const handleNext = () => {
    if (selectedFile && job) {
      const requiredFiles = job.fileAttachmentsByTypes.filter(
        (file) => file.isRequired
      );
      const optionalFiles = job.fileAttachmentsByTypes.filter(
        (file) => !file.isRequired
      );
      const allFiles = [...requiredFiles, ...optionalFiles];

      const currentIndex = allFiles.findIndex(
        (item) =>
          item.fileAttachmentTypeId === selectedFile.fileAttachmentTypeId
      );

      const nextIndex = (currentIndex + 1) % allFiles.length;
      setSelectedFile(allFiles[nextIndex]);
    }
  };

  const reloadBuilders = () => {
    axiosInstance
      .get(`/api/cba/job-details/${id}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setJob(response.data);
        setJobBuilders(response.data.builders);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
        setLoading(false);
      });
  };

  const loadJobDetails = () => {
    axiosInstance
      .get(`/api/cba/job-details/${id}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setJob(response.data);
        setJobBuilders(response.data.builders);
        setSelectedFile(response.data.fileAttachmentsByTypes[0]);

        const requiredIds = new Set(
          Array.from({ length: 11 }, (_, i) => i + 1)
        );
        const presentIds = new Set(
          response.data.fileAttachmentsByTypes.map(
            (item: { fileAttachmentTypeId: any }) => item.fileAttachmentTypeId
          )
        );
        setReadyToSubmit([...requiredIds].every((id) => presentIds.has(id)));

        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
        setLoading(false);
      });
  };

  const reloadJob = () => {
    axiosInstance
      .get(`/api/cba/job-details/${id}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setJob(response.data);
        setJobBuilders(response.data.builders);
        const requiredIds = new Set(
          Array.from({ length: 11 }, (_, i) => i + 1)
        );
        const presentIds = new Set(
          response.data.fileAttachmentsByTypes.map(
            (item: { fileAttachmentTypeId: any }) => item.fileAttachmentTypeId
          )
        );
        setReadyToSubmit([...requiredIds].every((id) => presentIds.has(id)));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
        setLoading(false);
      });
  };

  const loadAllBuilders = () => {
    axiosInstance
      .post(`/api/cba/builders/search`)
      .then((response) => {
        setAllBuilders(response.data);
      })
      .catch((error) => {
        console.error("Error fetching job builder details:", error);
      });
  };

  const formatStatus = (status: string) => {
    switch (status.trim()) {
      case "New":
        return "Ready to Start";
      case "InPMReview":
        return "Awaiting PM Review";
      case "InSPMReview":
        return "Awaiting SPM Review";
      case "InDMReview":
        return "Awaiting DM Review";
      case "InOpsReview":
        return "Awaiting Ops Review";
      case "Approved":
        return "Approved";
      case "Rejected":
        return "Rejected";
      case "Busted":
        return "Busted";
      case "Completed":
        return "Completed";
      case "Paid":
        return "Paid";
      default:
        return status.length ? status : "New";
    }
  };

  React.useEffect(() => {
    if (id) {
      loadJobDetails();
      loadAllBuilders();
    }
  }, [id]);

  useEffect(() => {
    if (job?.reviewStatus) {
      setAbletoEdit(
        Boolean(job.reviewStatus) === false ||
          job.reviewStatus.trim() === "Rejected" ||
          job.reviewStatus.trim() === "New"
      );
    }
  }, [job?.reviewStatus]);

  useEffect(() => {
    if (job) {
      setAbletoEditBuilder(
        job.reviewStatus.trim() === "Rejected" ||
          job.reviewStatus.trim() === "New" ||
          job.reviewStatus === ""
      );
    }
  }, [job]);

  return (
    <>
      <Container
        component="main"
        disableGutters
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F5F5F5",
          height: "100dvh",
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TitleAndRegion
            logo={true}
            title="File Upload"
            showRegion={false}
            logoOnly
          />
          <Box
            sx={{
              display: "flex",
              flex: 1,
              px: 5,
              justifyContent: "space-between",
            }}
          >
            {job && (
              <Box
                sx={{
                  mt: 3.5,
                  ml: 2,
                }}
              >
                <Typography
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  <strong>{` ${job?.jobAddress} - ${job?.projectNumber}`}</strong>
                </Typography>
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    ml: 2,
                  }}
                >
                  <strong>Status:</strong>
                  {` ${formatStatus(job?.reviewStatus)}`}
                </Typography>
              </Box>
            )}

            {job && (
              <BuilderSplitModal
                job={job}
                jobBuilders={jobBuilders}
                allBuilders={allBuilders}
                setJobBuilders={setJobBuilders}
                reloadBuilders={reloadBuilders}
                active={ableToEditBuilder}
              />
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flex: 1,
            overflow: "auto",
          }}
        >
          {job && selectedFile && (
            <>
              <Checklist
                job={job}
                selectChecklistItem={selectChecklistItem}
                selectedFile={selectedFile}
              />
              <UploadZone
                job={job}
                reloadJob={reloadJob}
                selectChecklistItem={selectChecklistItem}
                selectedFile={selectedFile}
              />
            </>
          )}
        </Box>
        <AppBar
          position="static"
          sx={{
            top: "auto",
            bottom: 0,
          }}
        >
          <Toolbar>
            <Box
              sx={{
                marginLeft: "auto",
                gap: 1,
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>{job && <Comments jobId={job.jobId} />}</Box>
              <Box>
                <Button color="inherit" onClick={handleNext} sx={{ px: 3 }}>
                  Next
                </Button>
                <Button
                  color="inherit"
                  variant="outlined"
                  onClick={submitJob}
                  disabled={!ableToEdit}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
      </Container>
    </>
  );
}
