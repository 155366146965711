import axiosInstance from "./axiosInstance";

export const downloadFile = async (url: string, filename: string) => {
  try {
    const response = await axiosInstance.post(url, null, {
      responseType: "blob",
    });
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error("File download error:", error);
  }
};
