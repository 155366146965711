import * as React from "react";
import { Container, Box, Typography, Grid, Paper, Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RateReviewIcon from "@mui/icons-material/RateReview";
import TitleAndRegion from "../../components/TitleAndRegion";
import Copyright from "../../components/Copyright";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useMsal } from "@azure/msal-react";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
export default function Home() {
  const { instance, accounts } = useMsal();

  return (
    <Container
      component="main"
      disableGutters
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#F5F5F5",
        overflow: "hidden",
        padding: 0,
        margin: 0,
      }}
    >
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <TitleAndRegion title="Construction Bonus" logo />
          <Box p={2}>
            <Button
              disableElevation
              variant="contained"
              sx={{
                color: (theme) => theme.palette.common.white,
              }}
              onClick={() =>
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                })
              }
            >
              Logout
            </Button>
          </Box>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{ marginTop: 2, justifyContent: "center", p: 4 }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Button href="/upload" color="primary" fullWidth>
              <Paper
                sx={{
                  padding: 2,
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  transition: "0.3s",
                  ":hover": {
                    boxShadow: `0 0 20px #007FFF`,
                  },
                }}
              >
                <CloudUploadIcon color="action" sx={{ fontSize: 60 }} />
                <Typography variant="button">Upload</Typography>
              </Paper>
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button href="/review" color="primary" fullWidth>
              <Paper
                sx={{
                  padding: 2,
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  transition: "0.3s",
                  ":hover": {
                    boxShadow: `0 0 20px #007FFF`, // Blue glow effect
                  },
                }}
              >
                <RateReviewIcon color="action" sx={{ fontSize: 60 }} />
                <Typography variant="button">Review</Typography>
              </Paper>
            </Button>
          </Grid>
          {accounts[0].idTokenClaims?.roles?.includes("Payroll") && (
            <Grid item xs={12} sm={12} md={8}>
              <Button href="/payroll" color="primary" fullWidth>
                <Paper
                  sx={{
                    padding: 1,
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    transition: "0.3s",
                    ":hover": {
                      boxShadow: `0 0 20px #007FFF`,
                    },
                  }}
                >
                  <RequestQuoteIcon color="action" sx={{ fontSize: 60 }} />
                  <Typography variant="button">Payroll</Typography>
                </Paper>
              </Button>
            </Grid>
          )}
          {accounts[0].idTokenClaims?.roles?.includes("Admin") && (
            <Grid item xs={12} sm={12} md={8}>
              <Button href="/admin" color="primary" fullWidth>
                <Paper
                  sx={{
                    padding: 1,
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    alignItems: "center",
                    transition: "0.3s",
                    ":hover": {
                      boxShadow: `0 0 20px #007FFF`,
                    },
                  }}
                >
                  <AdminPanelSettingsIcon
                    color="action"
                    sx={{ fontSize: 60 }}
                  />
                  <Typography variant="button">Admin</Typography>
                </Paper>
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      <Copyright />
    </Container>
  );
}
