import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  Chip,
  CardActionArea,
  Dialog,
  DialogTitle,
  Theme,
  Paper,
  Grid,
  Button,
  Skeleton,
  CardContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { commentsProps } from "../../providers/types";
import AxiosInstance from "../../utils/axiosInstance";
import theme from "../../theme";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";

interface Props {
  defaultIndex?: number;
  current: {
    files: any[];
    type: string;
    prev: any;
  };
}

const FileModal = ({ defaultIndex, current }: Props) => {
  const [open, setOpen] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(defaultIndex || 0);
  const [comments, setComments] = useState<commentsProps[]>([]);
  const [fileUrls, setFileUrls] = useState<(string | null)[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchFileStream = useCallback(
    async (fileAttachmentId: number, index: number) => {
      try {
        setLoading(true);
        const response = await AxiosInstance.get(
          `/api/cba/attachmentfile/${fileAttachmentId}`,
          {
            responseType: "blob",
          }
        );
        const url = URL.createObjectURL(response.data);
        setFileUrls((prevUrls) => {
          const newUrls = [...prevUrls];
          newUrls[index] = url;
          return newUrls;
        });
        setLoading(false);
        return url;
      } catch (error) {
        console.error("Error fetching file stream:", error);
        setLoading(false);
        return null;
      }
    },
    []
  );

  const loadFile = useCallback(
    async (index: number) => {
      if (!fileUrls[index]) {
        await fetchFileStream(current.files[index].fileAttachmentId, index);
      }
    },
    [current.files, fileUrls, fetchFileStream]
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
    loadFile(currentFileIndex);
  }, [currentFileIndex, loadFile]);

  const handleClose = useCallback(() => {
    setOpen(false);
    fileUrls.forEach((url) => {
      if (url) URL.revokeObjectURL(url);
    });
    setFileUrls([]);
  }, [fileUrls]);

  const handleNext = useCallback(() => {
    if (currentFileIndex < current.files.length - 1) {
      const nextIndex = currentFileIndex + 1;
      setCurrentFileIndex(nextIndex);
      loadFile(nextIndex);
    }
  }, [currentFileIndex, current.files.length, loadFile]);

  const handlePrevious = useCallback(() => {
    if (currentFileIndex > 0) {
      const prevIndex = currentFileIndex - 1;
      setCurrentFileIndex(prevIndex);
      loadFile(prevIndex);
    }
  }, [currentFileIndex, loadFile]);

  const loadComments = useCallback(async () => {
    if (current.prev.job?.jobId) {
      const response = await AxiosInstance.get(
        `/api/cba/comments/${current.prev.job.jobId}`
      );
      setComments(response.data);
    }
  }, [current.prev.job]);

  useEffect(() => {
    if (open && current.prev.job) {
      loadComments();
      if (current.files.length > 0 && !fileUrls[currentFileIndex]) {
        loadFile(currentFileIndex);
      }
    }
  }, [
    open,
    current.prev.job,
    current.files,
    currentFileIndex,
    fileUrls,
    loadComments,
    loadFile,
  ]);

  const getFileAttachmentTypeName = useCallback(
    (fileAttachmentTypeId: number) => {
      return current.prev.job?.fileAttachmentTypes.find(
        (type: { fileAttachmentTypesId: number }) =>
          type.fileAttachmentTypesId === fileAttachmentTypeId
      )?.name;
    },
    [current.prev.job]
  );

  const getFileIcon = useCallback((fileName: string) => {
    if (fileName.toLowerCase().endsWith(".pdf")) {
      return <PictureAsPdfIcon fontSize="large" color="primary" />;
    } else if (/\.(jpg|jpeg|png|gif)$/i.test(fileName)) {
      return <ImageIcon fontSize="large" color="primary" />;
    } else {
      return <InsertDriveFileIcon fontSize="large" color="action" />;
    }
  }, []);

  const file = current.files[currentFileIndex];
  const isPdfFile = file?.fileName?.toLowerCase()?.endsWith(".pdf");
  const fileUrl = fileUrls[currentFileIndex];

  return (
    <>
      <CardActionArea
        onClick={handleOpen}
        sx={{
          width: "auto",
          height: "auto",
          borderRadius: "15px",
          "&:hover": {
            transform: "translateY(10px)",
          },
        }}
      >
        <Card
          elevation={2}
          sx={{
            height: "12rem",
            width: "20rem",
            display: "flex",
            flexDirection: "column",
            borderRadius: "10px",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              transform: "translateY(-5px)",
              boxShadow: theme.shadows[4],
            },
          }}
        >
          <CardContent
            sx={{ flex: 1, display: "flex", flexDirection: "column", p: 2 }}
          >
            <Box display="flex" alignItems="center" mb={1}>
              {getFileIcon(file?.fileName || "")}
              <Box ml={1}>
                <Chip
                  label={
                    getFileAttachmentTypeName(file?.fileAttachmentTypeId) ||
                    "File"
                  }
                  size="small"
                  color="primary"
                  variant="outlined"
                />
              </Box>
            </Box>
            <Typography
              variant="subtitle1"
              component="h2"
              sx={{
                fontWeight: "bold",
                mb: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
              }}
            >
              {file?.fileName || "Unnamed File"}
            </Typography>
            <Box sx={{ mt: "auto" }}>
              <Typography
                variant="caption"
                color="text.secondary"
                display="block"
              >
                Created by: {file?.createdBy?.split("@")[0] || "Unknown"}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                display="block"
              >
                Last Modified: {new Date(file?.updatedOn).toLocaleDateString()}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </CardActionArea>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          sx: {
            maxHeight: "90vh",
            overflowY: "auto",
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: (theme: Theme) => theme.palette.primary.main,
            color: theme.palette.common.white,
          }}
        >
          {`${current?.prev.job?.jobAddress || ""} - ${
            current?.prev?.job?.jobNumber || ""
          }`}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.common.white,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={0} sx={{ height: "calc(90vh - 64px)" }}>
          <Grid item xs={9} sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
                backgroundColor: "#F5F5F5",
              }}
            >
              <IconButton
                onClick={handlePrevious}
                disabled={currentFileIndex === 0 || loading}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Typography
                variant="subtitle1"
                component="div"
                textAlign="center"
              >
                {file
                  ? `${getFileAttachmentTypeName(
                      file.fileAttachmentTypeId
                    )} - ${file.fileName}`
                  : "No File"}
              </Typography>
              <IconButton
                onClick={handleNext}
                disabled={
                  currentFileIndex === current.files.length - 1 || loading
                }
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
            <Paper
              elevation={3}
              sx={{
                flexGrow: 1,
                overflow: isPdfFile ? "hidden" : "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height="100%" />
              ) : isPdfFile ? (
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    height: "100%",
                  }}
                >
                  <iframe
                    src={fileUrl || ""}
                    width="100%"
                    height="100%"
                    title="pdf preview"
                  />
                </Box>
              ) : (
                <img
                  src={fileUrl || ""}
                  alt={file?.fileName}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "70vh",
                    objectFit: "contain",
                  }}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={3} display="flex" flexDirection="column">
            <Box sx={{ p: 2, flex: 1, overflow: "auto" }}>
              {Boolean(comments.length) ? (
                comments.map((comment) => (
                  <Box key={comment.id} sx={{ mb: 2 }}>
                    <Typography variant="body1">{comment.comment}</Typography>
                    <Typography variant="caption">{`Created by: ${comment.createdBy}`}</Typography>
                  </Box>
                ))
              ) : (
                <Box>
                  <Typography>No comments available</Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                p: 1,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                href={`/upload/create/${current.prev.job?.jobId}`}
                fullWidth
              >
                Edit Files
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default FileModal;
