import React from "react";
import {
  Box,
  Typography,
  MenuItem,
  MenuList,
  Popover,
  Link,
  Button,
} from "@mui/material";
import { useData } from "../../providers/DataProvider";
import { RegionProps } from "../../providers/types";
import EditIcon from "@mui/icons-material/Edit";
import Logo from "../../assets/images/logo.svg";

type TitleAndRegionProps = {
  logo?: boolean;
  title: string;
  showRegion?: boolean;
  logoOnly?: boolean;
};

const TitleAndRegion: React.FC<TitleAndRegionProps> = ({
  logo = false,
  title = "",
  showRegion = true,
  logoOnly = false,
}) => {
  const { regions, selectedRegion, updateSelectedRegion } = useData();
  const [hover, setHover] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setHover(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setHover(false);
  };

  const handleRegionSelect = (region: RegionProps) => {
    updateSelectedRegion(region);
    localStorage.setItem("selectedRegion", JSON.stringify(region));
    handleClose();
  };

  return (
    <Box
      sx={{
        display: "flex",
        p: logo ? 5 : "unset",
      }}
    >
      {logo && (
        <Box
          mr={4}
          sx={{
            display: "flex",
            alignSelf: "center",
          }}
        >
          <Link href="/">
            <img src={Logo} alt="logo" width={120} />
          </Link>
        </Box>
      )}
      {!logoOnly && (
        <Box
          onMouseOver={() => {
            if (showRegion) {
              setHover(true);
            }
          }}
          onMouseOut={() => setHover(false)}
          sx={{
            display: "flex",
            cursor: "pointer",
            flexDirection: "column",
            mt: -1,
          }}
        >
          <Typography variant="h5" ml={0.5}>{`${title}`}</Typography>
          {selectedRegion && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                "-webkit-text-stroke": hover ? "0.5px #003057" : "unset",
                borderRadius: "15px",
                mt: -1.5,
              }}
            >
              <Button
                onClick={handleClick}
                disabled={!showRegion}
                sx={{
                  color: "#757897",
                  textTransform: "capitalize",
                  ml: -0.5,
                }}
                endIcon={
                  <Box
                    onClick={handleClick}
                    sx={{
                      visibility: hover ? "visible" : "hidden",
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </Box>
                }
              >
                Region: {selectedRegion ? selectedRegion.regionName : "Loading"}
              </Button>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <MenuList>
                  {regions.map((region) => (
                    <MenuItem
                      key={region.regionId}
                      onClick={() => handleRegionSelect(region)}
                      sx={{ "&:hover": { backgroundColor: "#f4f4f4" } }}
                    >
                      {region.regionName}
                    </MenuItem>
                  ))}
                </MenuList>
              </Popover>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default TitleAndRegion;
