/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  Theme,
  Paper,
  Grid,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { useData } from "../../providers/DataProvider";
import { commentsProps, JobDetailsProps } from "../../providers/types";
import theme from "../../theme";
import axiosInstance from "../../utils/axiosInstance";

interface Props {
  jobId: number;
}

const PayrollModal = ({ jobId }: Props) => {
  const { role } = useData();
  const [open, setOpen] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [files, setFiles] = useState<any[]>([]);
  const [job, setJob] = useState<JobDetailsProps | undefined>(undefined);
  const [comments, setComments] = useState<commentsProps[]>([]);
  const [fileStreamUrl, setFileStreamUrl] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFiles([]);
    setFileStreamUrl(undefined);
    if (fileStreamUrl) {
      URL.revokeObjectURL(fileStreamUrl); // Revoke the object URL to free up memory
    }
  };

  const handleNext = () => {
    if (files && currentFileIndex < files.length - 1) {
      setCurrentFileIndex(currentFileIndex + 1);
      fetchFileStream(files[currentFileIndex + 1].fileAttachmentId);
    }
  };

  const handlePrevious = () => {
    if (currentFileIndex > 0) {
      setCurrentFileIndex(currentFileIndex - 1);
      fetchFileStream(files[currentFileIndex - 1].fileAttachmentId);
    }
  };

  const fetchFileStream = (fileAttachmentId: number) => {
    setLoading(true);
    axiosInstance
      .get(`/api/cba/attachmentfile/${fileAttachmentId}`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = URL.createObjectURL(response.data);
        setFileStreamUrl(url);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching file stream:", error);
        setLoading(false);
      });
  };

  const loadComments = async () => {
    if (jobId) {
      axiosInstance.get(`/api/cba/comments/${jobId}`).then((response) => {
        setComments(response.data);
      });
    }
  };

  const loadJobDetails = (jobId: number, role: string | null) => {
    axiosInstance
      .get(`/api/cba/job-details/${jobId}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setJob(response.data);
        setFiles(response.data.fileAttachments);
        if (response.data.fileAttachments.length > 0) {
          fetchFileStream(response.data.fileAttachments[0].fileAttachmentId);
        }
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
      });
  };

  useEffect(() => {
    if (open) {
      loadComments();
      loadJobDetails(jobId, role);
    }
  }, [jobId, open, role]);

  const isPdfFile = files[currentFileIndex]?.fileName
    ?.toLowerCase()
    ?.endsWith(".pdf");

  const getFileAttachmentTypeName = (fileAttachmentTypeId: number) => {
    return job?.fileAttachmentTypes.find(
      (type) => type.fileAttachmentTypesId === fileAttachmentTypeId
    )?.name;
  };

  return (
    <>
      <Tooltip title={"Review"}>
        <IconButton onClick={handleOpen}>
          <RateReviewIcon />
        </IconButton>
      </Tooltip>
      <Dialog maxWidth="lg" open={open} onClose={handleClose} fullWidth>
        <DialogTitle
          sx={{
            backgroundColor: (theme: Theme) => theme.palette.primary.main,
            color: theme.palette.common.white,
          }}
        >
          {`${job?.jobAddress} - ${job?.jobNumber}`}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.common.white,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={0} sx={{ height: "calc(100vh - 115px)" }}>
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
                backgroundColor: "#F5F5F5",
              }}
            >
              <IconButton
                onClick={handlePrevious}
                disabled={currentFileIndex === 0 || loading}
              >
                <NavigateBeforeIcon />
              </IconButton>
              <Typography
                variant="subtitle1"
                component="div"
                textAlign="center"
              >
                {`${
                  getFileAttachmentTypeName(
                    files[currentFileIndex]?.fileAttachmentTypeId
                  ) || ""
                } - ${files[currentFileIndex]?.fileName}`}
              </Typography>
              <IconButton
                onClick={handleNext}
                disabled={currentFileIndex === files.length - 1 || loading}
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
            <Paper
              elevation={3}
              sx={{
                flexGrow: 1,
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {loading ? (
                <CircularProgress />
              ) : isPdfFile ? (
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    height: "100%",
                  }}
                >
                  <iframe
                    src={fileStreamUrl}
                    width="100%"
                    height="100%"
                    title="pdf preview"
                  />
                </Box>
              ) : (
                <img
                  src={fileStreamUrl}
                  alt={files[currentFileIndex]?.fileName}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "70vh", // Set max height relative to view height
                    objectFit: "contain",
                  }}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default PayrollModal;
