/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  Stack,
} from "@mui/material";
import FileTree from "../../components/Drawer/FileTree";
import SearchBar from "../../components/SearchBar";
import AddIcon from "@mui/icons-material/Add";
import MainContent from "../../components/MainContent/MainContent";
import BackIcon from "@mui/icons-material/Restore";
import TitleAndRegion from "../../components/TitleAndRegion";
import { useData } from "../../providers/DataProvider";
import { useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UploaderViewModal from "../../modals/UploaderViewModel";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "../../utils/axiosInstance";
import { useMsal } from "@azure/msal-react";

export default function UploadHome() {
  const { instance } = useMsal();
  const {
    allJobs,
    companies,
    communities,
    selectedRegion,
    selectedCompany,
    loadCompanies,
    loadRegions,
    loadJobs,
    loadCommunities,
  } = useData();
  const [current, setCurrent] = React.useState<{
    files: any[];
    type: string;
    prev: any;
  }>({
    files: companies,
    type: "company",
    prev: {
      type: "company",
    },
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const jobQuery = React.useMemo(() => searchParams.get("job"), [searchParams]);
  const { role } = useData();

  const loadJobDetails = (jobId: number, role: string | null) => {
    axiosInstance
      .get(`/api/cba/job-details/${jobId}`, {
        headers: {
          roleType: role,
        },
      })
      .then((response) => {
        setCurrent({
          ...current,
          files: response.data.fileAttachments,
          type: "file",
          prev: {
            jobId: jobId,
            job: response.data,
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching job details:", error);
      });
  };

  React.useEffect(() => {
    loadRegions();
    loadCompanies();
    loadJobs();
  }, []);

  useEffect(() => {
    if (selectedCompany && selectedRegion) {
      loadCommunities();
    }
  }, [selectedCompany, selectedRegion]);

  useEffect(() => {
    if (jobQuery) {
      loadJobDetails(parseInt(jobQuery), role);
    }
  }, [jobQuery, role]);

  return (
    <Container
      component="main"
      disableGutters
      maxWidth={false}
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#F5F5F5",
        overflow: "hidden",
        padding: 0,
        margin: 0,
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FileTree
        companies={companies}
        communities={communities}
        jobs={allJobs as any}
        drawerWidth={320}
        current={current}
        setCurrent={setCurrent}
      />
      <Box
        sx={{
          flexGrow: 1,
          padding: 2,
        }}
      >
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flex: 1,
            justifyContent: "space-between",
          }}
        >
          <Box>
            <TitleAndRegion
              title={
                current.type === "file"
                  ? current.prev.job.jobAddress
                  : "File Explorer"
              }
            />
            {current.type !== "company" && (
              <Button
                color="secondary"
                variant="contained"
                disableElevation
                onClick={() => {
                  setCurrent({
                    files: companies,
                    type: "company",
                    prev: {
                      ...current.prev,
                      type: current.type,
                      files: current.files,
                    },
                  });
                }}
                startIcon={
                  <BackIcon
                    sx={{
                      color: "#0061FF",
                    }}
                  />
                }
              >
                <Typography
                  color="#0061FF"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  Start Over
                </Typography>
              </Button>
            )}
            {/* {current.type === "file" && (
              <Typography>{current.prev.job.reviewStatus}</Typography>
            )} */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              <SearchBar current={current} setCurrent={setCurrent} />
              <Button
                size="small"
                disableElevation
                variant="contained"
                sx={{
                  color: (theme) => theme.palette.common.white,
                  fontSize: "0.8rem",
                }}
                onClick={() =>
                  instance.logoutRedirect({
                    postLogoutRedirectUri: "/",
                  })
                }
              >
                Logout
              </Button>
            </Box>
            <Stack spacing={1} direction="row" mt={2} justifyContent="flex-end">
              {current.type === "file" && (
                <>
                  {current.prev.job &&
                    Boolean(current.files.length) &&
                    current.type === "file" && (
                      <UploaderViewModal
                        job={current.prev.job}
                        files={current.prev.job?.fileAttachments}
                      />
                    )}
                  <Button
                    color="secondary"
                    variant="contained"
                    href={`/upload/create/${current.prev.jobId}`}
                    disableElevation
                    startIcon={
                      <CloudUploadIcon
                        sx={{
                          color: "#0061FF",
                        }}
                      />
                    }
                  >
                    <Typography
                      color="#0061FF"
                      sx={{
                        textTransform: "capitalize",
                      }}
                    >
                      Edit/Add Files
                    </Typography>
                  </Button>
                </>
              )}
              <Button
                color="secondary"
                variant="contained"
                href="/upload/create"
                onClick={() => {}}
                disableElevation
                startIcon={
                  <AddIcon
                    sx={{
                      color: "#0061FF",
                    }}
                  />
                }
              >
                <Typography
                  color="#0061FF"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  Create
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flex: 1,
            gap: 1,
            justifyContent:
              current.type !== "company" ? "space-between" : "flex-end",
          }}
        ></Box>
        {selectedRegion && selectedCompany && (
          <MainContent current={current} setCurrent={setCurrent} />
        )}
      </Box>
    </Container>
  );
}
